import React, { Fragment } from 'react';
import StarRatings from 'react-star-ratings';
import styles from './RatingOverview.module.css';

const RatingOverview = (props) => {
    const { ratings } = props;
    const totalStars = ratings.length;

    const renderReviews = (ratings, totalStars) => {
        const contentToRender = ratings.map((rating, ratingIndex) => {
            return (
                <div
                    key={ratingIndex}
                    className="sm:grid-cols-12 xl:grid-cols-6 grid items-center pt-1">
                    <div
                        className={`overflow-hidden h-2 text-xs flex sm:col-start-1 sm:col-end-8 xl:col-start-1 xl:col-end-5 ${styles.ProgressBar}`}>
                        <div
                            style={{ width: `${rating}%` }}
                            className={`shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center ${styles.ProgressBarCompleted}`}></div>
                    </div>

                    <div className="sm:ml-4 sm:col-start-8 sm:col-end-13 xl:col-start-5 xl:col-end-7">
                        <StarRatings
                            starDimension="18px"
                            starSpacing="2px"
                            rating={totalStars - ratingIndex}
                            starRatedColor="orange"
                            numberOfStars={totalStars}
                            name="rating"
                        />
                        <span className={`ml-2 ${styles.BaseColorHighlight}`}>{rating}%</span>
                    </div>
                </div>
            );
        });

        return contentToRender;
    };

    return <Fragment>{renderReviews(ratings, totalStars)}</Fragment>;
};

export default RatingOverview;
