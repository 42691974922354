import React, { useEffect, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { Grid } from '@material-ui/core';
import { DataGrid } from '@material-ui/data-grid';
import { GridOverlay } from '@material-ui/data-grid';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { MdDeleteForever } from 'react-icons/md';
import styles from './Members.module.css';
import ReactModalAdapter from 'helpers/ReactModalAdapter';
import tw from 'twin.macro';
import styled from 'styled-components';
import { IoMdCloseCircleOutline } from 'react-icons/io';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import _ from 'lodash';
import { FaRegCheckCircle } from 'react-icons/fa';
import { API } from 'aws-amplify';
import { RingLoader } from 'components/shared';
import Tooltip from '@material-ui/core/Tooltip';
import { FaCheckCircle } from 'react-icons/fa';
import { IoCloseCircle } from 'react-icons/io5';

const useStyles = makeStyles((theme) => ({
    root: {
        '& .MuiDataGrid-root': {
            border: 0
        },
        '& .MuiDataGrid-footer': {
            justifyContent: 'center'
        },
        '& .MuiDataGrid-colCellTitle': {
            color: '#283E59',
            fontWeight: 300,
            fontSize: 12
        },
        '& .MuiDataGrid-colCellTitleContainer': {
            justifyContent: 'center'
        },
        '& .MuiDataGrid-cellWithRenderer': {
            justifyContent: 'center'
        },
        '& .MuiDataGrid-overlay': {
            backgroundColor: '#fff',
            zIndex: 1
        }
    },
    materialSelect: {
        '& .MuiOutlinedInput-input': {
            padding: '10.5px 14px'
        }
    },
    inputRoot: {
        '& .MuiOutlinedInput-notchedOutline': {
            border: '1px solid rgba(149, 170, 201, 1)'
        }
    },
    input: {
        color: '#283E59',
        fontSize: '1rem',
        fontWeight: '600',
        fontStyle: 'normal'
    },
    inputLabel: {
        fontWeight: 'normal',
        fontSize: '20px',
        color: '#95AAC9'
    }
}));

const LightTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: theme.palette.common.white,
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: theme.shadows[1],
        fontSize: 14
    }
}))(Tooltip);

// prettier-ignore
const InviteMemberModal = styled(ReactModalAdapter)`
  &.InviteMemberModal__overlay {
    ${tw`fixed inset-0 z-50 bg-black bg-opacity-30`}
    transition: opacity 2000ms ease-in-out;
  }
  &.InviteMemberModal__content {
    ${tw`xl:mx-auto sm:m-auto max-w-screen-xl absolute inset-0 flex flex-col justify-center items-center rounded-lg outline-none md:w-1/2 lg:w-1/3 max-h-3/4 m-auto my-auto bg-white self-start items-start justify-self-start px-5 pb-10`}
    height: fit-content;
    min-height: 512px;
    min-width: 665px;
    max-height: 95%;
  }
  .content {
    ${tw`w-full lg:p-16`}
  }
`;

const fetchMembers = async () => {
    return await API.get(process.env.REACT_APP_API_ENDPOINT_NAME, '/admin/members').then(
        (response) => {
            return response;
        }
    );
};

const grantPresenterRole = async (id) => {
    return await API.post(
        process.env.REACT_APP_API_ENDPOINT_NAME,
        `/admin/user/${id}/grant/presenter`
    ).then((response) => {
        return response;
    });
};

const removeUser = async (id) => {
    return await API.post(process.env.REACT_APP_API_ENDPOINT_NAME, `/admin/user/${id}/remove`).then(
        (response) => {
            return response;
        }
    );
};

const Members = (props) => {
    const classes = useStyles();
    const [isInviteMemberModalActive, setIsInviteMemberModalActive] = useState(false);
    const [isOnFinishModalActive, setIsOnFinishModalActive] = useState(false);
    const [inviteMemberFormState, setInviteMemberFormState] = useState({
        data: {
            first_name: '',
            last_name: '',
            occupation: '',
            email: ''
        }
    });
    const [checkPresenter, setCheckPresenter] = useState(false);
    const [checkAdminRole, setCheckAdminRole] = useState(false);
    const [loading, setLoading] = useState(false);
    const [inviteError, setInviteError] = useState(false);

    const queryClient = useQueryClient();

    const { isLoading, error, data, isFetching } = useQuery({
        queryKey: ['members'],
        queryFn: fetchMembers,
        staleTime: 60000
    });

    const grantPresenterRoleMutation = useMutation({
        mutationFn: grantPresenterRole,
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['members'] });
        }
    });

    const removeUserMutation = useMutation({
        mutationFn: removeUser,
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['members'] });
        }
    });

    const onCheckChange = (event) => {
        setCheckPresenter(event.target.checked);
    };

    const onAdminCheckChange = (event) => {
        setCheckAdminRole(event.target.checked);
    };

    const onInputChangeHandler = (value, fieldName) => {
        let updatedInviteMemberState = _.cloneDeep(inviteMemberFormState);
        updatedInviteMemberState.data[fieldName] = value;
        setInviteMemberFormState(updatedInviteMemberState);
    };

    const onSubmitInviteMember = () => {
        setLoading(true);
        const inviteMemberData = _.cloneDeep(inviteMemberFormState.data);

        const postData = {
            body: { ...inviteMemberData, isPresenter: checkPresenter, isAdmin: checkAdminRole }
        };

        API.post(process.env.REACT_APP_API_ENDPOINT_NAME, '/admin/invite', postData)
            .then((response) => {
                setInviteMemberFormState({
                    data: {
                        first_name: '',
                        last_name: '',
                        occupation: '',
                        email: ''
                    }
                });
                setCheckPresenter(false);
                setCheckAdminRole(false);
                toggleInviteMemberModal();
                setIsOnFinishModalActive(true);
                setLoading(false);
                queryClient.invalidateQueries({ queryKey: ['members'] });
            })
            .catch((error) => {
                console.log(error);
                setInviteError('Invite failed, the user might already exist');
                setLoading(false);
            });
    };

    const inviteMemberModal = (
        <InviteMemberModal
            closeTimeoutMS={300}
            className={`InviteMemberModal`}
            isOpen={isInviteMemberModalActive}
            onRequestClose={() => {
                setIsInviteMemberModalActive(false);
            }}
            shouldCloseOnOverlayClick={true}>
            <div className="justify-items-end grid w-full mt-2">
                <IoMdCloseCircleOutline
                    className="cursor-pointer"
                    size={'1.5em'}
                    color={'#283E59'}
                    onClick={() => {
                        setIsInviteMemberModalActive(false);
                    }}
                />
            </div>

            <div className="flex flex-col w-full mt-2">
                <div className={`flex items-center justify-center`}>
                    <h4 className={`font-semibold text-2xl ${styles.InviteMemberModalHeader}`}>
                        Invite Member
                    </h4>
                </div>
            </div>

            <div className="flex flex-col w-full">
                <div className={`flex items-center justify-between space-x-5 mt-1`}>
                    <div className="flex flex-col justify-center w-full px-4 cursor-pointer">
                        <Grid container justifyContent="center">
                            <Grid item xs={12} lg={12}>
                                <Grid container className={styles.InviteMemberFormContainer}>
                                    <Grid item xs={6} className={styles.InviteMemberFormGrid}>
                                        <div className={styles.FieldContainer}>
                                            <TextField
                                                required
                                                variant="outlined"
                                                placeholder="First Name"
                                                className=""
                                                value={inviteMemberFormState.data.first_name || ''}
                                                onChange={(event) =>
                                                    onInputChangeHandler(
                                                        event.target.value,
                                                        'first_name'
                                                    )
                                                }
                                                fullWidth
                                                classes={{
                                                    root: classes.inputRoot
                                                }}
                                                InputProps={{
                                                    className: classes.input
                                                }}
                                                InputLabelProps={{
                                                    className: classes.inputLabel
                                                }}
                                            />
                                        </div>
                                    </Grid>
                                    <Grid item xs={6} className={styles.InviteMemberFormGrid}>
                                        <div className={styles.FieldContainer}>
                                            <TextField
                                                required
                                                variant="outlined"
                                                placeholder="Last Name"
                                                className=""
                                                value={inviteMemberFormState.data.last_name || ''}
                                                onChange={(event) =>
                                                    onInputChangeHandler(
                                                        event.target.value,
                                                        'last_name'
                                                    )
                                                }
                                                fullWidth
                                                classes={{
                                                    root: classes.inputRoot
                                                }}
                                                InputProps={{
                                                    className: classes.input
                                                }}
                                                InputLabelProps={{
                                                    className: classes.inputLabel
                                                }}
                                            />
                                        </div>
                                    </Grid>

                                    <Grid item xs={12} className={styles.InviteMemberFormGrid}>
                                        <div className={styles.FieldContainer}>
                                            <TextField
                                                required
                                                variant="outlined"
                                                placeholder="Internal id"
                                                className=""
                                                value={
                                                    inviteMemberFormState.data.org_internal_id || ''
                                                }
                                                onChange={(event) =>
                                                    onInputChangeHandler(
                                                        event.target.value,
                                                        'org_internal_id'
                                                    )
                                                }
                                                classes={{
                                                    root: classes.inputRoot
                                                }}
                                                InputProps={{
                                                    className: classes.input
                                                }}
                                                InputLabelProps={{
                                                    className: classes.inputLabel
                                                }}
                                            />
                                        </div>
                                    </Grid>

                                    <Grid item xs={12} className={styles.InviteMemberFormGrid}>
                                        <div className={styles.FieldContainer}>
                                            <TextField
                                                required
                                                variant="outlined"
                                                placeholder="Occupation"
                                                className=""
                                                value={inviteMemberFormState.data.occupation || ''}
                                                onChange={(event) =>
                                                    onInputChangeHandler(
                                                        event.target.value,
                                                        'occupation'
                                                    )
                                                }
                                                classes={{
                                                    root: classes.inputRoot
                                                }}
                                                InputProps={{
                                                    className: classes.input
                                                }}
                                                InputLabelProps={{
                                                    className: classes.inputLabel
                                                }}
                                            />
                                        </div>
                                    </Grid>

                                    <Grid item xs={12} className={styles.InviteMemberFormGrid}>
                                        <div className={styles.FieldContainer}>
                                            <TextField
                                                variant="outlined"
                                                placeholder="Email Address"
                                                className=""
                                                value={inviteMemberFormState.data.email || ''}
                                                onChange={(event) =>
                                                    onInputChangeHandler(
                                                        event.target.value,
                                                        'email'
                                                    )
                                                }
                                                classes={{
                                                    root: classes.inputRoot
                                                }}
                                                InputProps={{
                                                    className: classes.input
                                                }}
                                                InputLabelProps={{
                                                    className: classes.inputLabel
                                                }}
                                            />
                                        </div>
                                    </Grid>

                                    <Grid item xs={12} className={styles.InviteMemberFormGrid}>
                                        <div className={styles.FieldContainer}>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={checkPresenter}
                                                        onChange={onCheckChange}
                                                        name="checkedB"
                                                        color="primary"
                                                    />
                                                }
                                                label="Check to grant Educator role"
                                                className={styles.checkboxLabelStyling}
                                            />
                                        </div>
                                        <div className={styles.FieldContainer}>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={checkAdminRole}
                                                        onChange={onAdminCheckChange}
                                                        name="checkedAdminRole"
                                                        color="primary"
                                                    />
                                                }
                                                label="Check to grant Admin role"
                                                className={styles.checkboxLabelStyling}
                                            />
                                        </div>
                                    </Grid>

                                    <Grid item xs={12} className={styles.SaveActionContainer}>
                                        {inviteError ? (
                                            <p style={{ color: 'red', textAlign: 'center' }}>
                                                {inviteError}
                                            </p>
                                        ) : null}
                                    </Grid>

                                    <Grid item xs={12} className={styles.SaveActionContainer}>
                                        <div className={styles.SaveAction}>
                                            <button
                                                className={`px-8 py-3 font-bold rounded focus:shadow-outline focus:outline-none transition duration-300 w-full ${styles.SaveButton}`}
                                                name={'Send Invitation'}
                                                onClick={() => {
                                                    onSubmitInviteMember();
                                                }}
                                                disabled={
                                                    inviteMemberFormState.data.first_name !== '' &&
                                                    inviteMemberFormState.data.last_name !== '' &&
                                                    inviteMemberFormState.data.occupation !== '' &&
                                                    inviteMemberFormState.data.email !== ''
                                                        ? false
                                                        : true
                                                }>
                                                {loading ? (
                                                    <div
                                                        className={`flex justify-center items-center`}>
                                                        <div className="lds-ring">
                                                            <div></div>
                                                            <div></div>
                                                            <div></div>
                                                            <div></div>
                                                        </div>
                                                    </div>
                                                ) : (
                                                    'Send Invitation'
                                                )}
                                            </button>
                                        </div>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </div>
                </div>
            </div>
        </InviteMemberModal>
    );

    const toggleInviteMemberModal = () => {
        setIsInviteMemberModalActive(!isInviteMemberModalActive);
    };

    const onFinishModal = (
        <InviteMemberModal
            closeTimeoutMS={300}
            className="InviteMemberModal"
            isOpen={isOnFinishModalActive}
            onRequestClose={() => {
                setIsOnFinishModalActive(false);
            }}
            shouldCloseOnOverlayClick={true}>
            <div className="flex flex-col self-center justify-center w-full min-h-full px-4">
                <div className="flex items-center justify-center w-full mt-5 text-center">
                    <FaRegCheckCircle size={'6em'} color={'#F9B041'} />
                </div>

                <div className="justify-center w-full mt-5 text-center">
                    <h2 className={`font-semibold text-4xl ${styles.OnFinishModalHeader}`}>
                        Invitation sent!
                    </h2>
                </div>

                <div className="justify-center w-full mt-5 text-center">
                    <p className={`font-medium text-lg ${styles.OnFinishModalPara}`}>
                        Your invitation have been succesfully sent
                    </p>
                </div>

                <div className="justify-center w-full mt-5 text-center">
                    <button
                        className={`inline-block rounded py-1 sm:px-16 font-bold text-lg text-white mr-5 outline-none ${styles.Button} ${styles.ButtonActive}`}
                        onClick={() => {
                            setIsOnFinishModalActive(false);
                        }}>
                        Done
                    </button>
                </div>
            </div>
        </InviteMemberModal>
    );

    const columns = [
        {
            field: 'id',
            hide: true
        },
        {
            field: 'first_name',
            headerName: 'First Name',
            width: 180,
            renderCell: (params) => (
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    style={{ padding: 5 }}
                    alignItems="center">
                    <Grid item lg={12}>
                        <div className={`${styles.PresentationsColumnFirstNameContainer}`}>
                            <span
                                className={`${styles.PresentationsColumnSpan} ${styles.PresentationsColumnSpanTitle}`}>
                                {params.row.first_name}
                            </span>
                        </div>
                    </Grid>
                </Grid>
            ),
            disableColumnMenu: true,
            disableColumnFilter: true,
            sortable: false
        },
        {
            field: 'last_name',
            headerName: 'Last Name',
            width: 180,
            renderCell: (params) => (
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    style={{ padding: 5 }}
                    alignItems="center">
                    <Grid item lg={12}>
                        <div>
                            <span
                                className={`${styles.PresentationsColumnSpan} ${styles.PresentationsColumnSpanTitle}`}>
                                {params.row.last_name}
                            </span>
                        </div>
                    </Grid>
                </Grid>
            ),
            disableColumnMenu: true,
            disableColumnFilter: true,
            sortable: false
        },
        {
            field: 'email',
            headerName: 'Email',
            width: 200,
            renderCell: (params) => (
                <div className={styles.Reviews}>
                    <LightTooltip title={params.row.email ? params.row.email : '-'}>
                        <span className={styles.ReviewsDetails}>
                            {params.row.email ? params.row.email : '-'}
                        </span>
                    </LightTooltip>
                </div>
            ),
            disableColumnMenu: true,
            disableColumnFilter: true,
            sortable: false,
            align: 'left'
        },
        {
            field: 'noOfPresentations',
            headerName: 'No. of Modules',
            width: 180,
            renderCell: (params) => (
                <span className={styles.DurationSpan}>
                    {params.row.presentationCount ? params.row.presentationCount : '-'}
                </span>
            ),
            disableColumnMenu: true,
            disableColumnFilter: true,
            sortable: false
        },
        {
            field: 'views',
            headerName: 'Views',
            width: 80,
            renderCell: (params) => (
                <span className={styles.ViewsSpan}>{params.row.viewCount}</span>
            ),
            disableColumnMenu: true,
            disableColumnFilter: true,
            sortable: false
        },
        {
            field: 'grantPresenterRole',
            headerName: 'Educator Role',
            width: 130,
            renderCell: (params) => (
                <button
                    onClick={() => {
                        if (params.row.isPresenter !== true)
                            grantPresenterRoleMutation.mutate(params.row.id);
                    }}>
                    {params.row.isPresenter === true ? (
                        <FaCheckCircle size={'1.8em'} color={'#34C647'} />
                    ) : (
                        <IoCloseCircle size={'1.8em'} color={'#F12B2C'} />
                    )}
                </button>
            ),
            disableColumnMenu: true,
            disableColumnFilter: true,
            sortable: false
        },
        {
            field: 'remove',
            headerName: 'Remove',
            width: 100,
            renderCell: (params) => (
                <button
                    className={styles.RemoveAction}
                    onClick={() => removeUserMutation.mutate(params.row.id)}>
                    {<MdDeleteForever size={'1.8em'} color={'#7F7F7F'} />}
                </button>
            ),
            disableColumnMenu: true,
            disableColumnFilter: true,
            sortable: false
        }
    ];

    const CustomLoadingOverlay = () => {
        const classes = useStyles();

        return (
            <GridOverlay className="z-1 bg-white opacity-100">
                <RingLoader />
            </GridOverlay>
        );
    };

    const CustomNoRowsOverlay = () => {
        const classes = useStyles();

        return (
            <GridOverlay className={classes.root}>
                {isLoading ? (
                    <RingLoader />
                ) : (
                    <div className="-lg:px-8 -md:px-4 container flex flex-col items-center justify-center max-w-screen-lg mx-auto text-center">
                        <img
                            className={`my-12`}
                            src={require('../../../images/sample/presentation/standing 17.png')}
                            alt="No Result User"
                        />

                        <p className={`${styles.NoResultMessage} text-center`}>
                            There are currently no
                            <br />
                            members. Invite some!
                        </p>
                    </div>
                )}
            </GridOverlay>
        );
    };

    const DataTable = (props) => {
        const rows = props.rows || [];

        return (
            <div style={{ height: 560, width: '100%' }} className={classes.root}>
                <DataGrid
                    rows={rows}
                    columns={columns}
                    rowHeight={75}
                    pageSize={6}
                    autoPageSize
                    showCellRightBorder={false}
                    showColumnRightBorder={false}
                    disableSelectionOnClick
                    components={{
                        NoRowsOverlay: CustomNoRowsOverlay,
                        LoadingOverlay: CustomLoadingOverlay
                    }}
                    loading={isLoading || isFetching || removeUserMutation.isLoading}
                />
            </div>
        );
    };

    const temporaryRows = [];

    const [membersData, setMembersData] = useState(null);

    useEffect(() => {
        setMembersData(data);
    }, [data]);

    return (
        <>
            <Grid container direction="row" justifyContent="center" className="mt-12">
                <Grid item lg={12}>
                    <p className={styles.Title}>Members</p>
                </Grid>

                <Grid item lg={12} className={`${styles.PresentationsContainer}`}>
                    <Grid container direction="row" justifyContent="space-between">
                        <Grid item lg={8}></Grid>

                        <Grid item lg={4} style={{ textAlign: 'end' }}>
                            <button
                                className={`inline-block rounded py-1 sm:px-8 font-bold text-lg text-white mr-5 outline-none ${styles.Button} ${styles.ButtonActive}`}
                                onClick={() => {
                                    toggleInviteMemberModal();
                                }}>
                                Invite Member
                            </button>
                        </Grid>

                        <Grid item lg={12} style={{ marginTop: 10 }}>
                            <DataTable rows={membersData || temporaryRows} />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

            {inviteMemberModal}
            {onFinishModal}
        </>
    );
};

export default Members;
