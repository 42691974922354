import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { v4 as uuidv4 } from 'uuid';
import styles from './PreviewImportedMembersTable.module.css';
import TextField from '@material-ui/core/TextField';
import _ from 'lodash';
import { FaPencilAlt } from 'react-icons/fa';
import { ImCancelCircle } from 'react-icons/im';
import { MdDone } from 'react-icons/md';
import { RiDeleteBin5Line } from 'react-icons/ri';
import { useAtom } from 'jotai';
import { csvFileDataAtom } from '../Invites';

const columns = [
    { id: 'name', label: 'Name' },
    { id: 'last_name', label: 'Last Name' },
    { id: 'internal_id', label: 'Internal Id' },
    { id: 'occupation', label: 'Occupation' },
    { id: 'email', label: 'Email' },
    { id: 'action', label: 'Action', minWidth: 130 }
];

const useStyles = makeStyles({
    root: {
        width: '100%',
        border: '0px',
        boxShadow: '0px 0px white'
    },
    container: {
        maxHeight: 440,
        border: 'none'
    },
    inputRoot: {
        '& .MuiOutlinedInput-notchedOutline': {
            border: '1px solid rgba(149, 170, 201, 1)'
        },
        '& .MuiOutlinedInput-input': {
            padding: '5.5px 6px'
        },
        marginTop: '2px',
        marginBottom: '2px'
    },
    input: {
        color: 'rgba(149, 170, 201, 1)',
        fontSize: '1rem',
        fontWeight: 'normal',
        fontStyle: 'normal'
    }
});

export default function StickyHeadTable(props) {
    const classes = useStyles();
    const [isEditingIndex, setIsEditingIndex] = useState(null);
    const [isEditingValue, setIsEditingValue] = useState(null);
    const [processedRows, setProcessedRows] = useAtom(csvFileDataAtom); //useState(_.cloneDeep(props.rows));
    let updatedColumns = columns.filter((column) => column.id !== 'action');

    const onDeleteResultValue = (index) => {
        const updatedResultValues = [...processedRows];
        updatedResultValues.splice(index, 1);
        setProcessedRows(updatedResultValues);
        // dispatch(onChange(updatedResultValues));
    };

    const onStartEditResultValue = (index) => {
        const updatedResultValues = [...processedRows];
        setIsEditingIndex(index);
        setIsEditingValue(updatedResultValues[index]);
    };

    const onChangeEditResultValue = (value, fieldName) => {
        let updatedRow = { ...isEditingValue };
        updatedRow[fieldName] = value;
        setIsEditingValue(updatedRow);
    };

    const onFinishEditResultValue = () => {
        const updatedResultValues = [...processedRows];
        updatedResultValues[isEditingIndex] = { ...isEditingValue };
        setProcessedRows(updatedResultValues);
        // dispatch(onChange(updatedResultValues));
        setIsEditingIndex(null);
        setIsEditingValue(null);
    };

    const onCancelEditResultValue = () => {
        setIsEditingIndex(null);
        setIsEditingValue(null);
    };

    let containerUI = null;

    if (processedRows && processedRows.length > 0) {
        containerUI = (
            <>
                <TableBody>
                    {processedRows.map((row, index) => {
                        let isInEditingMode = false;
                        let actionUI = (
                            <>
                                <div
                                    className={`flex flex-row justify-between w-1/4`}>
                                    <FaPencilAlt
                                        size={'1.5em'}
                                        className={`${styles.IconEdit}`}
                                        onClick={() =>
                                            onStartEditResultValue(index)
                                        }></FaPencilAlt>
                                    <RiDeleteBin5Line
                                        size={'1.5em'}
                                        className={`${styles.IconCross}`}
                                        onClick={() =>
                                            onDeleteResultValue(index)
                                        }></RiDeleteBin5Line>
                                </div>
                            </>
                        );

                        if (index === isEditingIndex) {
                            isInEditingMode = true;
                            actionUI = (
                                <>
                                    <div
                                        className={`flex flex-row justify-between w-1/2`}>
                                        <MdDone
                                            size={'1.5em'}
                                            className={`${styles.IconEdit}`}
                                            onClick={() =>
                                                onFinishEditResultValue()
                                            }></MdDone>
                                        <ImCancelCircle
                                            size={'1.5em'}
                                            className={`${styles.IconCross}`}
                                            onClick={() =>
                                                onCancelEditResultValue()
                                            }></ImCancelCircle>
                                    </div>
                                </>
                            );
                        } else if (isEditingIndex !== null) {
                            actionUI = null;
                        }

                        let tableRowUI = (
                            <TableRow
                                hover
                                role="checkbox"
                                tabIndex={-1}
                                key={index}>
                                {updatedColumns.map((column) => {
                                    const value = row[column.id];
                                    return (
                                        <TableCell
                                            key={uuidv4()}
                                            align={column.align}>
                                            {value}
                                        </TableCell>
                                    );
                                })}
                                <TableCell key={uuidv4()} align={'center'}>
                                    {actionUI}
                                </TableCell>
                            </TableRow>
                        );

                        if (isInEditingMode) {
                            tableRowUI = (
                                <TableRow
                                    hover
                                    role="checkbox"
                                    tabIndex={-1}
                                    key={index}>
                                    {updatedColumns.map((column) => {
                                        return (
                                            <TableCell
                                                key={column.id}
                                                align={column.align}>
                                                <TextField
                                                    id="outlined-full-width"
                                                    margin="none"
                                                    fullWidth
                                                    classes={{
                                                        root: classes.inputRoot
                                                    }}
                                                    InputLabelProps={{
                                                        shrink: true
                                                    }}
                                                    className={
                                                        styles.ViewObjective
                                                    }
                                                    value={
                                                        isEditingValue[
                                                            column.id
                                                        ] || ''
                                                    }
                                                    onChange={(event) =>
                                                        onChangeEditResultValue(
                                                            event.target.value,
                                                            column.id
                                                        )
                                                    }
                                                    variant="outlined"
                                                    inputProps={{
                                                        maxLength: 999
                                                    }}
                                                    InputProps={{
                                                        className: classes.input
                                                    }}
                                                />
                                            </TableCell>
                                        );
                                    })}
                                    <TableCell key={'action'} align={'center'}>
                                        {actionUI}
                                    </TableCell>
                                </TableRow>
                            );
                        }

                        return tableRowUI;
                    })}
                </TableBody>
            </>
        );
    } else {
        containerUI = (
            <>
                <TableBody>
                    <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={uuidv4()}>
                        <TableCell key={uuidv4()} align={'center'} colSpan={5}>
                            <div className="-lg:px-8 -md:px-4 container flex flex-col items-center justify-center max-w-screen-lg mx-auto text-center">
                                <img
                                    className={`my-12`}
                                    src={require('../../../../images/sample/presentation/standing 17.png')}
                                    alt="No Members to Import"
                                />

                                <p
                                    className={`${styles.NoResultMessage} text-center`}>
                                    There are currently no
                                    <br />
                                    members. File is Empty!
                                </p>
                            </div>
                        </TableCell>
                    </TableRow>
                </TableBody>
            </>
        );
    }

    return (
        <Paper className={classes.root}>
            <TableContainer
                className={`${classes.container} ${styles.ImportTable}`}>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            {columns.map((column) => (
                                <TableCell
                                    key={uuidv4()}
                                    align={column.align}
                                    style={{ minWidth: column.minWidth }}>
                                    {column.label}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    {containerUI}
                </Table>
            </TableContainer>
        </Paper>
    );
}
