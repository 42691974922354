import { Auth } from 'aws-amplify';

const formConfig = {
    id: 'user-home',
    label: 'Edit Page',
    fields: [
        {
            name: 'suggestion_text',
            label: 'Suggestion title',
            component: 'text'
        }
    ],
    loadInitialValues() {
        return fetch(
            'https://bh-dev-page-data.s3.amazonaws.com/landingpage/user-home.json'
        ).then((response) => response.json());
    },
    async onSubmit(formData) {
        return fetch(
            'https://sutbynq5dj.execute-api.us-east-1.amazonaws.com//dev/pages-data?file=landingpage/user-home.json',
            {
                method: 'POST',
                body: JSON.stringify(formData),
                headers: {
                    'Content-type': 'application/json; charset=UTF-8',
                    authorization: `${(await Auth.currentSession())
                        .getAccessToken()
                        .getJwtToken()}`
                }
            }
        )
            .then((response) => response)
            .then((data) => console.log(data))
            .catch((e) => console.error(e));
    }
};

export default (props) => {
    return formConfig;
};
