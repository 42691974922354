import React, { useState, useEffect } from 'react';
import AnimationRevealPage from 'helpers/AnimationRevealPage.js';
import { Container as ContainerBase } from 'components/misc/Layouts';
import tw from 'twin.macro';
import styled from 'styled-components';
import { css } from 'styled-components/macro'; //eslint-disable-line
// import illustration from 'images/signup-illustration.svg';
import logo from 'images/logo.svg';
// import googleIconImageSrc from 'images/google-icon.png';
// import twitterIconImageSrc from 'images/twitter-icon.png';
// import { ReactComponent as SignUpIcon } from 'feather-icons/dist/icons/user-plus.svg';
import { Auth } from 'aws-amplify';
import { useHistory, useLocation } from 'react-router-dom';
// import { useUser } from 'util/auth.js';

const Container = tw(
    ContainerBase
)`min-h-screen bg-primary-100 text-white font-medium flex justify-center`;
const Content = tw.div`max-w-screen-xl m-0 sm:mx-20 sm:my-16 bg-white text-gray-900 shadow sm:rounded-lg flex justify-center flex-1`;
const MainContainer = tw.div`lg:w-1/2 xl:w-5/12 p-6 sm:p-12`;
const LogoLink = tw.a``;
const LogoImage = tw.img`h-20 mx-auto`;
const MainContent = tw.div`mt-12 flex flex-col items-center`;
const Heading = tw.h1`text-2xl xl:text-3xl font-extrabold`;
const FormContainer = tw.div`w-full flex-1 mt-8`;

const Form = tw.form`mx-auto max-w-xs`;
const Input = tw.input`w-full px-8 py-4 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white mt-5 first:mt-0`;
const SubmitButton = styled.button`
    ${tw`bg-primary-500 hover:bg-primary-900 focus:shadow-outline focus:outline-none flex items-center justify-center w-full py-4 mt-5 font-semibold tracking-wide text-gray-100 transition-all duration-300 ease-in-out rounded-lg`}
    .icon {
        ${tw`w-6 h-6 -ml-2`}
    }
    .text {
        ${tw`ml-3`}
    }
`;

const FormSignup = ({
    resendButtonText = 'Create a new password',
    loginText = 'Login',
    tosUrl = '/tos',
    privacyPolicyUrl = '/privacy',
    signInUrl = '/login'
}) => {
    const history = useHistory();
    const location = useLocation();
    const [password, setPassword] = useState('');
    const [password2, setPassword2] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [logError, setLogError] = useState('');
    const [infoMsg, setInfoMsg] = useState('');
    const [resetSuccess, setResetSuccess] = useState(false);
    const [pwdError, setPwdError] = useState(false);

    const search = new URLSearchParams(location.search);

    const username = search.get('username') || '';
    const code = search.get('code') || '';

    useEffect(() => {
        if (username === '' && code === '') {
            setLogError('Reset link is not valid');
            setResetSuccess(true);
        }
    }, []);

    const passwordChange = (event) => setPassword(event.target.value.trim());
    const password2Change = (event) => setPassword2(event.target.value.trim());

    const pwdRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d\w\W]{8,}$/g;

    async function resetPassword(e) {
        e.preventDefault();
        setIsLoading(true);
        if (password.length > 0) {
            const pwdFound = password.match(pwdRegex);

            if (password.trim() !== password2.trim()) {
                setLogError('Passwords do not match');
            } else if (password.length < 8 || pwdFound === null) {
                setLogError('See password requirements');
                setPwdError(true);
            } else {
                try {
                    await Auth.forgotPasswordSubmit(username, code, password);
                    setInfoMsg(
                        'Password reset successfully. You can now login with your new password.'
                    );
                    setResetSuccess(true);
                    console.log('code reset successfully');
                    setIsLoading(false);
                } catch (err) {
                    console.log('error resending code: ', err);
                    setLogError('error resending code: ' + err);
                    setIsLoading(false);
                }
            }
        } else {
            setLogError('Fill in all the fields');
            setIsLoading(false);
        }
    }

    function redirectToLogin(e) {
        e.preventDefault();
        history.push('/login');
    }

    return (
        <Form>
            {logError ? (
                <p style={{ color: 'red', textAlign: 'center' }}>{logError}</p>
            ) : null}
            <>
                {!logError && infoMsg ? (
                    <p tw="text-center">{infoMsg}</p>
                ) : null}
                {resetSuccess ? (
                    <>
                        <SubmitButton type="button" onClick={redirectToLogin}>
                            <span className="text">{loginText}</span>
                        </SubmitButton>
                    </>
                ) : (
                    <>
                        <Input
                            type="password"
                            style={{
                                borderColor:
                                    logError && password.length < 8 ? 'red' : ''
                            }}
                            required
                            placeholder="Password"
                            value={password}
                            onChange={passwordChange}
                        />
                        <Input
                            type="password"
                            style={{
                                borderColor:
                                    logError && password.length < 8 ? 'red' : ''
                            }}
                            required
                            placeholder="Repeat password"
                            value={password2}
                            onChange={password2Change}
                        />
                        <p
                            style={pwdError ? { color: 'red' } : {}}
                            tw="mt-6 text-xs text-gray-800 text-center">
                            Required: Password length at least 8 characters.{' '}
                            <br /> A lowercase letter, an uppercase letter, a
                            number.
                        </p>
                        <SubmitButton type="button" onClick={resetPassword}>
                            <span className="text">{resendButtonText}</span>
                        </SubmitButton>

                        <p tw="mt-8 text-sm text-gray-600 text-center">
                            Already have an account?{' '}
                            <a
                                href={signInUrl}
                                tw="border-b border-gray-500 border-dotted">
                                Log In
                            </a>
                        </p>
                    </>
                )}
            </>
        </Form>
    );
};

export default ({ logoLinkUrl = '/', headingText = '' }) => (
    <AnimationRevealPage>
        <Container>
            <Content>
                <MainContainer>
                    <LogoLink href={logoLinkUrl}>
                        <LogoImage src={logo} />
                    </LogoLink>
                    <MainContent>
                        <Heading>{headingText}</Heading>
                        <FormContainer>
                            <FormSignup />
                        </FormContainer>
                    </MainContent>
                </MainContainer>
            </Content>
        </Container>
    </AnimationRevealPage>
);
