import React from 'react';
import styles from './RingLoader.module.css';

const RingLoader = (props) => {

  return (
    <div className={`${styles['lds-ring']}`}><div></div><div></div><div></div><div></div></div>
  );
}

export default RingLoader;
