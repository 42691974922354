import React, { useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import styles from './AdminToolBar.module.css';
import { useLocation } from 'react-router-dom';
import { getQueryParam } from 'shared/utils/router';

const tabs = [
    { name: 'presentations', label: 'Modules' },
    { name: 'members', label: 'Members' },
    { name: 'invites', label: 'Invites' },
    { name: 'compliance', label: 'Compliance' }
];

const NavigationTab = ({ selectedTab, tab, onHandleTabSelect }) => {
    return (
        <button
            className={selectedTab === tab.name ? styles.ActiveNavLink : ''}
            onClick={() => onHandleTabSelect(tab.name)}>
            <p>{tab.label}</p>
        </button>
    );
};

const AdminToolBar = (props) => {
    const [selectedTab, setSelectedTab] = useState('presentations');
    let location = useLocation();

    useEffect(() => {
        props.onTabSelect(selectedTab);
        const tab = getQueryParam('tab', location.search);
        if (tab) {
            onHandleTabSelect(tab);
        }

        return () => {};
    }, []);

    const onHandleTabSelect = (tab) => {
        setSelectedTab(tab);
        props.onTabSelect(tab);
    };

    return (
        <Grid container item xs={12} lg={12} justifyContent="center" alignItems="center">
            <div className={styles.AppBarMenu}>
                {tabs.map((tab, tabIndex) => (
                    <NavigationTab
                        tab={tab}
                        key={tabIndex}
                        selectedTab={selectedTab}
                        onHandleTabSelect={onHandleTabSelect}
                    />
                ))}
            </div>
        </Grid>
    );
};

export default AdminToolBar;
