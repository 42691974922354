import React from "react";
import { CircularProgress } from "@material-ui/core";
import { FileDrop } from 'react-file-drop';

const DropFile = (props) => {

    const { loading } = props;

    const onDropFiles = (files, event) => {
        props.onFileSelected(files);
    }

    return (
        <FileDrop
            onDrop={(files, event) => onDropFiles(files, event)} >
            {
                loading ?
                    <div className="fileLoader-div">
                        <CircularProgress />
                    </div>
                    :
                    <div className={`flex flex-col items-center py-16`} style={{ border: '1px dashed rgba(110, 132, 163, 0.4)' }}>
                        <svg width="108" height="108" viewBox="0 0 108 108" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clipPath="url(#clip0)">
                                <path d="M95.7437 24.9307L87.6642 16.8516V4.80052C87.6642 2.15346 85.5107 0 82.8635 0H16.5335C13.9196 0 11.7929 2.12667 11.7929 4.74061V84.2706C11.7929 86.8846 13.9196 89.0112 16.5335 89.0112H19.215V91.653C19.215 94.267 21.3416 96.3936 23.9558 96.3936H34.2396C35.1133 96.3936 35.8217 95.6855 35.8217 94.8116C35.8217 93.9377 35.1133 93.2296 34.2396 93.2296H23.9558C23.0863 93.2296 22.379 92.5223 22.379 91.653V28.0461C22.379 27.1721 21.6707 26.464 20.797 26.464C19.9233 26.464 19.215 27.1721 19.215 28.0461V85.8472H16.5335C15.6642 85.8472 14.957 85.1399 14.957 84.2706V4.74061C14.957 3.87134 15.6642 3.16406 16.5335 3.16406H82.8637C83.7661 3.16406 84.5003 3.89813 84.5003 4.80052V13.6877L78.658 7.84582C78.3612 7.54925 77.959 7.3826 77.5394 7.3826H23.9558C21.3416 7.3826 19.215 9.50928 19.215 12.1232V20.6816C19.215 21.5555 19.9233 22.2636 20.797 22.2636C21.6707 22.2636 22.379 21.5555 22.379 20.6816V12.123C22.379 11.2537 23.0863 10.5465 23.9558 10.5465H75.9572V26.0466C75.9572 26.9203 76.6653 27.6284 77.539 27.6286L93.0431 27.6311V91.6528C93.0431 92.5221 92.3358 93.2294 91.4666 93.2294H81.3057C80.432 93.2294 79.7237 93.9375 79.7237 94.8114C79.7237 95.6853 80.432 96.3934 81.3057 96.3934H91.4666C94.0805 96.3934 96.2072 94.2667 96.2072 91.6528V26.0495C96.2072 25.63 96.0405 25.2277 95.7437 24.9307ZM79.1212 24.4648V12.7837L90.8051 24.4669L79.1212 24.4648Z" fill="#01539D" />
                                <path d="M76.6737 37.0103H38.8268C37.9531 37.0103 37.2448 37.7184 37.2448 38.5923C37.2448 39.4662 37.9531 40.1743 38.8268 40.1743H76.6737C77.5474 40.1743 78.2558 39.4662 78.2558 38.5923C78.2558 37.7184 77.5474 37.0103 76.6737 37.0103Z" fill="#01539D" />
                                <path d="M76.6737 46.3403H38.8268C37.9531 46.3403 37.2448 47.0485 37.2448 47.9224C37.2448 48.7963 37.9531 49.5044 38.8268 49.5044H76.6737C77.5474 49.5044 78.2558 48.7963 78.2558 47.9224C78.2558 47.0485 77.5474 46.3403 76.6737 46.3403Z" fill="#01539D" />
                                <path d="M76.6737 55.8567H69.1966C68.3229 55.8567 67.6146 56.5648 67.6146 57.4387C67.6146 58.3126 68.3229 59.0208 69.1966 59.0208H76.6737C77.5474 59.0208 78.2557 58.3126 78.2557 57.4387C78.2557 56.5648 77.5474 55.8567 76.6737 55.8567Z" fill="#01539D" />
                                <path d="M61.7511 59.0208C62.6248 59.0208 63.3332 58.3126 63.3332 57.4387C63.3332 56.5648 62.6248 55.8567 61.7511 55.8567H38.8268C37.9531 55.8567 37.2448 56.5648 37.2448 57.4387C37.2448 58.3126 37.9531 59.0208 38.8268 59.0208H61.7511Z" fill="#01539D" />
                                <path d="M63.5498 86.6107L59.3323 82.3934V97.6877C59.3323 98.5616 58.624 99.2697 57.7503 99.2697C56.8766 99.2697 56.1682 98.5616 56.1682 97.6877V82.3934L51.9508 86.6107C51.3329 87.2283 50.3312 87.2286 49.7136 86.6105C49.0957 85.9927 49.0957 84.9909 49.7136 84.3733L56.6317 77.4554C56.9405 77.1466 57.3455 76.9922 57.7503 76.9922C58.1551 76.9922 58.5601 77.1466 58.8689 77.4554L65.787 84.3733C66.4048 84.9911 66.4048 85.9929 65.787 86.6105C65.1694 87.2283 64.1676 87.2281 63.5498 86.6107Z" fill="#01539D" />
                                <path d="M57.7503 68.2615C46.794 68.2615 37.8802 77.1748 37.8802 88.1307C37.8802 99.0864 46.794 108 57.7503 108C68.7066 108 77.6204 99.0864 77.6204 88.1307C77.6204 77.1748 68.7066 68.2615 57.7503 68.2615ZM57.7503 104.836C48.5386 104.836 41.0443 97.3421 41.0443 88.1309C41.0443 78.9197 48.5386 71.4257 57.7503 71.4257C66.9619 71.4257 74.4563 78.9197 74.4563 88.1309C74.4563 97.3421 66.9619 104.836 57.7503 104.836Z" fill="#01539D" />
                            </g>
                            <defs>
                                <clipPath id="clip0">
                                    <rect width="108" height="108" fill="white" />
                                </clipPath>
                            </defs>
                        </svg>

                        <span>Drag & Drop Files</span>
                    </div>
            }
        </FileDrop>
    );
}

export default DropFile;