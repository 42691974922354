import React from 'react';

const styles = {
    textAlign: 'center',
    width: '100%'
};

const uploadButtonStyles = {
    marginLeft: '20px',
    border: '0.5px solid #F9FBFD',
    boxShadow: '0px 2px 10px -2px rgba(0, 0, 0, 0.16)',
    borderRadius: '5px',
    alignSelf: 'self-start',
    display: 'inline-block',
    padding: '10px 60px',
    cursor: 'pointer',
    backgroundColor: '#f9b041',
    color: '#fff',
    fontSize: '1em',
    outline: 'none',
    fontWeight: '500',
    whiteSpace: 'nowrap'
}

const FileChooser = (props) => {

    const onChange = (e) => {
        props.onFileSelected(e.target.files);
    }

    return (
        <div style={styles}>
            <label htmlFor="file-upload" style={uploadButtonStyles}>
                Upload file
                <input type="file"
                    style={{ display: 'none' }}
                    accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                    id="file-upload"
                    onChange={onChange} />
            </label>
        </div>
    );

}

export default FileChooser;
