import React, { useState, useEffect } from 'react';
import styles from './Invites.module.css';
import { Grid } from '@material-ui/core';
import { FileDropper } from 'components/shared/FileDropper';
import { FileChooser } from 'components/shared/UI';
import { PreviewImportedMembersTable } from './PreviewImportedMembersTable';
import { FaRegCheckCircle } from 'react-icons/fa';
import { atom, useAtom } from 'jotai';
import { API } from 'aws-amplify';
import { useMutation } from '@tanstack/react-query';
import { RingLoader } from 'components/shared';
import Papa from 'papaparse';

export const csvFileDataAtom = atom(null);

const sendBatchInvites = async (postData) => {
    return await API.post(
        process.env.REACT_APP_API_ENDPOINT_NAME,
        '/admin/bulk-invites',
        postData
    ).then((response) => {
        return response;
    });
};

const Invites = (props) => {
    const [isParsingCSVFile, setIsParsingCSVFile] = useState(false);
    const [csvFileData, setCsvFileData] = useAtom(csvFileDataAtom);
    const [showSuccess, setShowSuccess] = useState(false);

    useEffect(() => {
        setCsvFileData(null);
    }, []);

    const postMutation = useMutation(sendBatchInvites, {
        onSuccess: (data) => {
            setShowSuccess(true);
        }
    });

    const sendInvites = () => {
        for (let item of csvFileData) {
            item['first_name'] = item['name'];
            delete item['name'];
            delete item[''];
        }
        const postData = {
            body: { ...csvFileData }
        };
        postMutation.mutate(postData);
    };

    const onFileSelected = (files) => {
        setCsvFileData(null);
        setIsParsingCSVFile(true);
        Papa.parse(files[0], {
            header: true,
            skipEmptyLines: true,
            complete: (results) => {
                setCsvFileData(results.data);
                setIsParsingCSVFile(false);
            },
            error: (error) => {
                console.log('Error parsing CSV file', error);
                setIsParsingCSVFile(false);
            }
        });
    };

    let containerUI = null;

    containerUI = (
        <>
            <Grid container direction="row" justify="center">
                <Grid item lg={8}>
                    <p className={`text-lg py-10 ${styles.IntroductionMessage}`}>
                        Upload a csv file to import multiple members.
                        <br />
                        CSV expected headers: name, last_name, internal_id, occupation, email
                    </p>
                </Grid>

                <Grid item lg={8}>
                    <FileDropper onFileSelected={onFileSelected} loading={isParsingCSVFile} />
                </Grid>

                <Grid item lg={8} className={`${styles.FileChooserContainer}`}>
                    <FileChooser onFileSelected={onFileSelected} loading={isParsingCSVFile} />
                </Grid>
            </Grid>
        </>
    );

    if (csvFileData) {
        containerUI = (
            <>
                <Grid container direction="row" justifyContent="center">
                    {postMutation.isLoading ? (
                        <div className="flex flex-col self-center justify-center w-full min-h-full px-4">
                            <div className="flex items-center justify-center w-full mt-5 text-center">
                                <RingLoader />
                            </div>
                        </div>
                    ) : (
                        <>
                            {showSuccess ? (
                                <div className="flex flex-col self-center justify-center w-full min-h-full px-4">
                                    <div className="flex items-center justify-center w-full mt-5 text-center">
                                        <FaRegCheckCircle size={'6em'} color={'#F9B041'} />
                                    </div>

                                    <div className="justify-center w-full mt-5 text-center">
                                        <h2
                                            className={`font-semibold text-4xl ${styles.OnFinishModalHeader}`}>
                                            Invitations registered.
                                        </h2>
                                    </div>

                                    <div className="justify-center w-full mt-5 text-center">
                                        <p
                                            className={`font-medium text-lg ${styles.OnFinishModalPara}`}>
                                            Your invitations will be sent over next 24 hours.
                                        </p>
                                    </div>
                                </div>
                            ) : (
                                <>
                                    <Grid item lg={12}>
                                        <PreviewImportedMembersTable />
                                    </Grid>

                                    <Grid item lg={3}>
                                        <div className="flex flex-col justify-center w-full mt-8 text-center">
                                            <button
                                                className={`inline-block rounded py-2 sm:px-8 font-bold text-base text-white outline-none ${styles.Button} ${styles.ButtonActive}`}
                                                onClick={() => sendInvites()}>
                                                Send invitations
                                            </button>
                                        </div>
                                    </Grid>
                                </>
                            )}
                        </>
                    )}
                </Grid>
            </>
        );
    }

    return (
        <Grid container direction="row" justify="center" className="mt-12">
            <Grid item lg={12}>
                <p className={styles.Title}>Batch Invites</p>
            </Grid>

            <Grid item lg={12} className={`${styles.InvitesContainer}`}>
                {containerUI}
            </Grid>
        </Grid>
    );
};

export default Invites;
