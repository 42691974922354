import React, { useState } from 'react';
import styles from './Compliance.module.css';
import { Grid } from '@material-ui/core';
import { PrimaryButton } from 'components/shared/UI/Theme';
import { FaRegCheckCircle } from 'react-icons/fa';

const Compliance = (props) => {
    const [complianceRequested, setComplianceRequested] = useState(false);

    let containerUI = null;

    function clickExport() {
        setComplianceRequested(true);
    }

    containerUI = (
        <>
            <Grid container direction="row" justify="center">
                <Grid item lg={8}>
                    {complianceRequested ? (
                        <>
                            <h3
                                className={`font-semibold text-3xl text-center pt-10 ${styles.IntroductionHeader}`}>
                                Export a copy of compliance data
                            </h3>

                            <p
                                className={`font-normal text-lg text-center py-10 ${styles.IntroductionMessage}`}>
                                When your data is ready, you'll get an email
                                with a download link.
                                <br />
                                The link will be valid for 1 week.
                                <br />
                                Data export has been requested and is in
                                progress.
                            </p>

                            <div className="flex items-center justify-center w-full mt-5 text-center">
                                <FaRegCheckCircle
                                    size={'6em'}
                                    color={'#F9B041'}
                                />
                            </div>
                        </>
                    ) : (
                        <>
                            <h3
                                className={`font-semibold text-3xl text-center pt-10 ${styles.IntroductionHeader}`}>
                                Export a copy of compliance data
                            </h3>

                            <p
                                className={`font-normal text-lg text-center py-10 ${styles.IntroductionMessage}`}>
                                When your data is ready, you'll get an email
                                with a download link.
                            </p>

                            <div className={'text-center'}>
                                <PrimaryButton onClick={() => clickExport()}>
                                    Create Export
                                </PrimaryButton>
                            </div>
                        </>
                    )}
                </Grid>
            </Grid>
        </>
    );

    return (
        <Grid container direction="row" justify="center" className="mt-12">
            <Grid item lg={12}>
                <h1 className={styles.Title}>Compliance</h1>
            </Grid>

            <Grid item lg={12} className={`${styles.ComplianceContainer}`}>
                {containerUI}
            </Grid>
        </Grid>
    );
};

export default Compliance;
