import React, { useState } from 'react';
import { IoMdStar } from 'react-icons/io';
import { RiAccountCircleFill } from 'react-icons/ri';
import tw from 'twin.macro';
import styled from 'styled-components';
import styles from './PresentationCard.module.css';
import { isNumberValueEmpty } from 'shared/utils/number/number';
import { uncacheImageURL } from 'shared/utils/cache';
import CertificateBadge from 'images/certificate/certificate-badge.svg';
import PresentationImageFallback from 'images/sample/presentation/presentation-hero-image.png';

const CardImage = styled.img`
    ${tw`w-12 h-12 bg-center bg-contain rounded-full`}
`;

const PresentationThumnailImage = React.memo(({ id }) => {
    let thumbnailSrc = '';
    if (id) {
        thumbnailSrc = `${process.env.REACT_APP_PRESENTATION_THUMBNAILS_URL}/thumbnails/presentations/${id}/thumbnail.jpeg`;
    }
    function imageError(evt) {
        evt.target.src = PresentationImageFallback;
    }
    return (
        <img
            className={`profile-image ${styles.PresentationThumnail}`}
            src={thumbnailSrc}
            onError={imageError}
            alt="Your avatar"></img>
    );
});

const Card = ({ source, icon, title, keywords, description }) => {
    const [isPresenterImageError, setIsPresenterImageError] = useState(null);
    const vd = source.videoDuration.split(':');
    const minutes = +vd[0] * 60 + +vd[1];

    return (
        <div className={`mt-6`}>
            <div className={`bg-white ${styles.Card}`}>
                <div className={`relative ${styles.PresentationThumnailContainer}`}>
                    <PresentationThumnailImage id={source.id} />
                </div>

                <div className={`pt-3 relative`}>
                    <h1
                        className={`text-blue font-medium text-xl px-4 ${styles.PresentationTitle}`}>
                        {title || 'NO-TITLE'}
                    </h1>
                    {source.approvedCE ? (
                        <img
                            alt="CertificateBadge"
                            src={CertificateBadge}
                            className={`${styles.CertificateRibbon}`}
                        />
                    ) : null}

                    <div className="flex items-center px-4 mt-2 space-x-2">
                        <ul className="flex justify-between w-4/5">
                            <li>
                                {keywords[0] ? (
                                    <button
                                        style={{
                                            fontSize: '9px',
                                            border: '1px solid #AFB3B8'
                                        }}
                                        className="text-blue hover:shadow focus:outline-none px-3 py-1 mr-1 font-medium border rounded-full">
                                        {keywords[0]}
                                    </button>
                                ) : null}
                                {keywords[1] ? (
                                    <button
                                        style={{
                                            fontSize: '9px',
                                            border: '1px solid #AFB3B8'
                                        }}
                                        className="text-blue hover:shadow focus:outline-none px-3 py-1 mr-1 font-medium border rounded-full">
                                        {keywords[1]}
                                    </button>
                                ) : null}
                                {keywords[2] ? (
                                    <button
                                        style={{
                                            fontSize: '9px',
                                            border: '1px solid #AFB3B8'
                                        }}
                                        className="text-blue hover:shadow focus:outline-none px-3 py-1 mr-1 font-medium border rounded-full">
                                        {keywords[2]}
                                    </button>
                                ) : null}
                            </li>
                            <li>
                                {minutes > 0 ? (
                                    <p
                                        className={`text-blue text-base font-medium whitespace-nowrap py-1 text-sm ${styles.PresentationTime}`}>
                                        {minutes} min
                                    </p>
                                ) : null}
                            </li>
                        </ul>
                    </div>
                    <p
                        className={`text-base font-medium mt-3 px-4 leading-loose tracking-wide ${styles.PresentationDescription}`}
                        style={{
                            height: '70px',
                            color: 'rgba(40, 62, 89, 1)'
                        }}>
                        {description}
                    </p>
                    <div className="flex items-end justify-between px-4 mt-2">
                        <div className="flex items-center">
                            {source.user.picture && !isPresenterImageError ? (
                                <CardImage
                                    src={uncacheImageURL(
                                        process.env.REACT_APP_PROFILE_IMAGES_URL +
                                            '/' +
                                            source.user.picture
                                    )}
                                    onError={() => {
                                        setIsPresenterImageError(true);
                                    }}
                                />
                            ) : (
                                <RiAccountCircleFill size={'3.125rem'} color="F9B041" />
                            )}
                            <div className="ml-2">
                                <h1 className="text-sm font-bold">
                                    {source.first_name} {source.last_name}
                                </h1>
                                <span className="text-yellow flex items-center text-xl font-medium">
                                    {source.user.reviewAvgRating ? (
                                        <IoMdStar size={'1.6em'} />
                                    ) : null}
                                    <span className="ml-1">
                                        {!isNumberValueEmpty(+source.user.reviewAvgRating)
                                            ? source.user.reviewAvgRating.toFixed(2)
                                            : source.user.reviewAvgRating}{' '}
                                    </span>
                                    {source.user.reviewCount && source.user.reviewCount > 0 ? (
                                        <span
                                            className="text-gray ml-2 text-base font-medium"
                                            style={{
                                                color: 'rgba(40, 62, 89, 1)'
                                            }}>
                                            ({source.user.reviewCount})
                                        </span>
                                    ) : null}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Card;
