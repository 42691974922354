import React, { Component, Fragment } from 'react';
import styles from './LongText.module.css';

class LongText extends Component {
    state = { showAll: false }
    showMore = () => this.setState({ showAll: true });
    showLess = () => this.setState({ showAll: false });
    render() {
        const { content, limit } = this.props;
        const { paraClasses, containerClasses, showMoreClasses, showLessClasses } = this.props;
        const { showAll } = this.state;

        if (content.length <= limit) {
            return (
                <div>
                    <div className={`${styles.Container} ${containerClasses}`}>
                        <p className={`${paraClasses} ${styles.DefaultPara}`}>
                            {content}
                        </p>
                    </div>
                </div>
            );
        }
        if (showAll) {
            return (
                <Fragment>
                    <div>
                        <div className={`${styles.Container} ${containerClasses}`}>
                            <p className={`${paraClasses} ${styles.DefaultPara}`}>
                                {content}
                            </p>
                        </div>
                    </div>
                    <span onClick={(e) => { e.stopPropagation(); this.showLess(); }}
                        className={`${styles.ShowMoreButton}  ${showLessClasses}`}>Show Less</span>
                </Fragment>
            )
        }
        const toShow = content.substring(0, limit);
        return <Fragment>
            <div>
                <div className={`${styles.Container} ${containerClasses}`}>
                    <p className={`${paraClasses} ${styles.DefaultPara}`}>
                        {toShow}
                    </p>
                    <div className={`${styles.ContainerShadow}`}></div>
                </div>
            </div>
            <div className={`${styles.ShowMoreButton} ${showMoreClasses} flex`}
                onClick={(e) => { e.stopPropagation(); this.showMore(); }}>
                <span>
                    Show More
                </span>
                <img alt="Down Arrow" src={require(`../../../../images/keyboard_arrow_down_24px_outlined.png`)} className=""></img>
            </div>
        </Fragment>
    }
}

export default LongText;