import React, { useState, useEffect } from 'react';
// import AnimationRevealPage from 'helpers/AnimationRevealPage.js';
import { Container as ContainerBase } from 'components/misc/Layouts';
import tw from 'twin.macro';
import styled from 'styled-components';
import { css } from 'styled-components/macro'; //eslint-disable-line
import illustration from 'images/login-illustration.svg';
import logo from 'images/logo.svg';
import googleIconImageSrc from 'images/google-icon.png';
import twitterIconImageSrc from 'images/twitter-icon.png';
import { ReactComponent as LoginIcon } from 'feather-icons/dist/icons/log-in.svg';
import { Auth, API } from 'aws-amplify';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import { useUser } from 'util/auth.js';
import { ON_LOGIN_REDIRECT_URL } from 'shared/utils/constants';

const Container = tw(
    ContainerBase
)`min-h-screen bg-primary-100 text-white font-medium flex justify-center`;
const Content = tw.div`max-w-screen-xl m-0 sm:mx-20 sm:my-16 bg-white text-gray-900 shadow sm:rounded-lg flex justify-center flex-1`;
const MainContainer = tw.div`lg:w-1/2 xl:w-5/12 p-6 sm:p-12`;
const MainContent = tw.div`mt-12 flex flex-col items-center`;
// const Heading = tw.h1`text-2xl xl:text-3xl font-extrabold`;
const FormContainer = tw.div`w-full flex-1 mt-8`;

const Form = tw.form`mx-auto max-w-xs`;
const Input = tw.input`w-full px-8 py-4 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white mt-5 first:mt-0`;
const SubmitButton = styled.button`
    ${tw`bg-primary-500 hover:bg-primary-900 focus:shadow-outline focus:outline-none flex items-center justify-center w-full py-4 mt-5 font-semibold tracking-wide text-gray-100 transition-all duration-300 ease-in-out rounded-lg`}
    .icon {
        ${tw`w-6 h-6 -ml-2`}
    }
    .text {
        ${tw`ml-3`}
    }
`;

let refresh = true;

const Logo = ({ logoLinkUrl = '/' }) => {
    const history = useHistory();
    const LogoLink = tw.a``;
    const LogoImage = tw.img`h-20 mx-auto`;
    let newLogo = '';
    let { org } = useParams();
    const onError = (e) => {
        //redirect to default login if org logo is not found
        history.push('/login');
    };

    if (org) {
        //get image from S3 bucket
        newLogo = `${process.env.REACT_APP_PROFILE_IMAGES_URL}/org-info/${org}/logo`;
    }

    return (
        <LogoLink href={logoLinkUrl}>
            <LogoImage src={newLogo ? newLogo : logo} onError={onError} />
        </LogoLink>
    );
};

const FormLogin = ({
    submitButtonText = 'Log in',
    SubmitButtonIcon = LoginIcon,
    tosUrl = '#',
    privacyPolicyUrl = '/privacy',
    signInUrl = '/login'
}) => {
    const location = useLocation();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [logError, setLogError] = useState('');
    const [infoMsg, setInfoMsg] = useState('');
    const [firstLogin, setFirstLogin] = useState(false);

    const { login, logout } = useUser();
    let { org } = useParams();

    const search = new URLSearchParams(location.search);

    const username = search.get('username') || '';
    const code = search.get('code') || '';

    const emailChange = (event) => setEmail(event.target.value.trim());
    const passwordChange = (event) => setPassword(event.target.value.trim());

    useEffect(() => {
        try {
            Auth.signOut();
        } catch (err) {
            console.log(err);
        }

        if (username !== '' && code !== '') {
            refresh = false;
            confirmCode();
        }

        if (refresh) {
            refresh = false;
            //refreshLogin();
        }
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    async function confirmCode() {
        setIsLoading(true);
        try {
            if (org) {
                await Auth.confirmSignUp(username, code, { clientMetadata: { org: org } });
            } else {
                await Auth.confirmSignUp(username, code);
            }
            setFirstLogin(true);
            setInfoMsg('Email confirmed successfully');
            setIsLoading(false);
        } catch (error) {
            console.log('error confirming email', error);
            setLogError('Link expired. Please request a new verification email.');
            setIsLoading(false);
        }
    }

    async function loginClick(e) {
        e.preventDefault();
        if (email.length === 0 || password.length === 0) {
            setLogError('Please fill in all fields');
        } else if (email.length > 0 && password.length > 0) {
            try {
                setIsLoading(true);
                setLogError('');
                let userIn = await login(email, password);
                let userSession = await Auth.currentAuthenticatedUser();

                const userInfo = await API.get(
                    process.env.REACT_APP_API_ENDPOINT_NAME,
                    '/user/info'
                );

                if (org) {
                    if (userInfo.orgId && userInfo.orgId.indexOf(org) === -1) {
                        setLogError('User does not belong to this organization');
                        logout();
                        setIsLoading(false);
                        return;
                    } else {
                        await Auth.updateUserAttributes(userSession, {
                            zoneinfo: org
                        });
                        userSession = await Auth.currentAuthenticatedUser({ bypassCache: true });
                    }
                } else {
                    if (userInfo.orgId && userInfo.orgId.indexOf('$') !== -1) {
                        setLogError(
                            "User belongs to an organization, use organization's login page"
                        );
                        logout();
                        setIsLoading(false);
                        return;
                    }
                }

                const onLoginRedirectURL = localStorage.getItem(ON_LOGIN_REDIRECT_URL);
                localStorage.removeItem(ON_LOGIN_REDIRECT_URL);

                if (firstLogin) {
                    API.get(process.env.REACT_APP_API_ENDPOINT_NAME, '/user/registration')
                        .then((response) => {
                            if (onLoginRedirectURL) {
                                window.location.replace(onLoginRedirectURL);
                            } else {
                                window.location.replace(
                                    process.env.REACT_APP_ROOT_URL + '/user/home'
                                );
                            }
                        })
                        .catch((error) => {
                            setIsLoading(false);
                            setLogError(error.message);
                            throw error;
                        });
                } else {
                    if (onLoginRedirectURL) {
                        window.location.replace(onLoginRedirectURL);
                    } else {
                        window.location.replace(process.env.REACT_APP_ROOT_URL + '/user/home');
                    }
                }
            } catch (error) {
                console.log('error signing up:', error);
                setIsLoading(false);
                setLogError(error.message);
            }
        }
    }

    return (
        <Form>
            {logError ? <p style={{ color: 'red', textAlign: 'center' }}>{logError}</p> : null}
            {!logError && infoMsg ? <p>{infoMsg}</p> : null}
            <Input
                type="email"
                placeholder="Email"
                value={email}
                onChange={emailChange}
                disabled={isLoading}
            />
            <Input
                type="password"
                placeholder="Password"
                value={password}
                onChange={passwordChange}
                disabled={isLoading}
            />
            <SubmitButton type="submit" onClick={loginClick}>
                {isLoading ? (
                    <div className="lds-ring">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                ) : (
                    <span className="text">{submitButtonText}</span>
                )}
            </SubmitButton>
        </Form>
    );
};

export default ({
    logoLinkUrl = '/',
    illustrationImageSrc = illustration,
    headingText = 'Sign In To Beaker Health',
    socialButtons = [
        {
            iconImageSrc: googleIconImageSrc,
            text: 'Sign In With Google',
            url: 'https://google.com'
        },
        {
            iconImageSrc: twitterIconImageSrc,
            text: 'Sign In With Twitter',
            url: 'https://twitter.com'
        }
    ],
    submitButtonText = 'Sign In',
    SubmitButtonIcon = LoginIcon,
    forgotPasswordUrl = '/forgot-password',
    resendUrl = '/resend',
    signupUrl = '/signup'
}) => {
    let { org } = useParams();

    return (
        <Container>
            <Content>
                <MainContainer>
                    <Logo />
                    <MainContent>
                        {/*<Heading>{headingText}</Heading>*/}
                        <FormContainer>
                            {/*<SocialButtonsContainer>
                {socialButtons.map((socialButton, index) => (
                  <SocialButton key={index} href={socialButton.url}>
                    <span className="iconContainer">
                      <img src={socialButton.iconImageSrc} className="icon" alt=""/>
                    </span>
                    <span className="text">{socialButton.text}</span>
                  </SocialButton>
                ))}
			</SocialButtonsContainer>
              <DividerTextContainer>
                <DividerText>Or Sign in with your e-mail</DividerText>
              </DividerTextContainer>*/}
                            <FormLogin />
                            <p tw="mt-6 text-xs text-gray-600 text-center">
                                <a
                                    href={forgotPasswordUrl}
                                    tw="border-b border-gray-500 border-dotted">
                                    Forgot Password ?
                                </a>
                            </p>
                            {org ? null : (
                                <p tw="mt-2 text-sm text-gray-600 text-center">
                                    Don't have an account?{' '}
                                    <a href={signupUrl} tw="border-b border-gray-500 border-dotted">
                                        Signup
                                    </a>
                                </p>
                            )}
                            <p tw="mt-2 text-sm text-gray-600 text-center">
                                Resend verification email?{' '}
                                <a href={resendUrl} tw="border-b border-gray-500 border-dotted">
                                    Resend
                                </a>
                            </p>
                            {/*<p tw="mt-8 text-sm text-gray-600 text-center">
                Dont have an account?{" "}
                <a href={signupUrl} tw="border-b border-gray-500 border-dotted">
                  Sign Up
                </a>
              </p>*/}
                        </FormContainer>
                    </MainContent>
                </MainContainer>
                {/*<IllustrationContainer>
          <IllustrationImage imageSrc={illustrationImageSrc} />
        </IllustrationContainer>*/}
            </Content>
        </Container>
    );
};
