import React, { useState, useEffect } from 'react';
import AnimationRevealPage from 'helpers/AnimationRevealPage.js';
import { Container as ContainerBase } from 'components/misc/Layouts';
import { API } from 'aws-amplify';
import tw from 'twin.macro';
import styled from 'styled-components';
import { css } from 'styled-components/macro'; //eslint-disable-line
import logo from 'images/logo.svg';
import { useUser } from 'util/auth.js';
import { useLocation } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { RingLoader } from 'components/shared';

const Container = tw(
    ContainerBase
)`min-h-screen bg-primary-100 text-white font-medium flex justify-center`;
const Content = tw.div`max-w-screen-xl m-0 sm:mx-20 sm:my-16 bg-white text-gray-900 shadow sm:rounded-lg flex justify-center flex-1`;
const MainContainer = tw.div`lg:w-1/2 xl:w-5/12 p-6 sm:p-12`;
const LogoLink = tw.a``;
const LogoImage = tw.img`h-20 mx-auto`;
// const MainContent = tw.div`mt-12 flex flex-col items-center`;
// const Heading = tw.h1`text-2xl xl:text-3xl font-extrabold`;
const FormContainer = tw.div`w-full flex-1 mt-8`;

const Form = tw.form`mx-auto max-w-xs`;
const Input = tw.input`w-full px-8 py-4 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white mt-5 first:mt-0`;
const SubmitButton = styled.button`
    ${tw`bg-primary-500 hover:bg-primary-900 focus:shadow-outline focus:outline-none flex items-center justify-center w-full py-4 mt-5 font-semibold tracking-wide text-gray-100 transition-all duration-300 ease-in-out rounded-lg`}
    .icon {
        ${tw`w-6 h-6 -ml-2`}
    }
    .text {
        ${tw`ml-3`}
    }
`;

const FormSignup = ({
    submitButtonText = 'Complete Registration',
    // resendButtonText = 'Resend verification code',
    // SubmitButtonIcon = SignUpIcon,
    // verifyButtonText = 'Verify',
    tosUrl = '/tos',
    privacyPolicyUrl = '/privacy',
    signInUrl = '/login',
    resendUrl = '/resend'
}) => {
    const history = useHistory();
    const location = useLocation();
    const [name, setName] = useState('');
    const [familyName, setFamilyName] = useState('');
    const [occupation, setoccupation] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [success, setSuccess] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [logError, setLogError] = useState('');
    const [infoMsg, setInfoMsg] = useState('');
    const [pwdError, setPwdError] = useState(false);
    const [logSuccess, setLogSuccess] = useState('');

    const { login } = useUser();

    const pwdRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d\w\W]{8,}$/g;

    const nameChange = (event) => setName(event.target.value);
    const familyNameChange = (event) => setFamilyName(event.target.value);
    const occupationChange = (event) => setoccupation(event.target.value);
    const passwordChange = (event) => setPassword(event.target.value.trim());

    const search = new URLSearchParams(location.search);

    const stripe_session_id = search.get('session_id') || '';
    const paymentSuccess = search.get('success' || '');

    useEffect(() => {
        setSuccess(true);
        setIsLoading(true);
        async function getEmail(stripe_session_id) {
            const sessionData = {
                body: { session_id: stripe_session_id }
            };

            API.post(
                process.env.REACT_APP_API_ENDPOINT_NAME_PUBLIC,
                '/stripe/get-email',
                sessionData
            ).then((response) => {
                //setLogSuccess(response);
                setEmail(response);
                setSuccess(false);
                setIsLoading(false);
            });
        }

        if (stripe_session_id !== '') {
            //get user email
            //setLogSuccess(stripe_session_id);

            getEmail(stripe_session_id);
        }
        console.log(search);
        console.log(paymentSuccess);
        if (paymentSuccess !== '' && paymentSuccess === 'true') {
            setLogSuccess('Payment has been successful, please complete registration');
        }
    }, []);

    function validateEmail(email) {
        const re = /^[^\s@]+@[^\s@]+$/;
        return re.test(String(email).toLowerCase());
    }

    async function signUp(e) {
        e.preventDefault();

        if (
            email.length > 0 &&
            password.length > 0 &&
            name.length > 0 &&
            familyName.length > 0 &&
            occupation.length > 0
        ) {
            const validEmail = validateEmail(email);
            const pwdFound = password.match(pwdRegex);

            if (validEmail === false) {
                setLogError('Input valid email');
            } else if (password.length < 8 || pwdFound === null) {
                setLogError('See password requirements');
                setPwdError(true);
            } else {
                setLogSuccess('');
                setIsLoading(true);
                try {
                    const username = email;
                    const family_name = familyName;

                    const userData = {
                        body: {
                            username: username,
                            password: password,
                            email: email,
                            first_name: name,
                            family_name: family_name,
                            occupation: occupation,
                            session_id: stripe_session_id
                        }
                    };

                    API.post(
                        process.env.REACT_APP_API_ENDPOINT_NAME_PUBLIC,
                        '/stripe/create-user',
                        userData
                    )
                        .then((response) => {
                            console.log(response);
                            setLogError('');
                            setSuccess(true);
                            setIsLoading(false);
                            //login
                            pageLogin(response.plan);
                        })
                        .catch((error) => {
                            console.log('error');
                            console.log(error.response);
                            setSuccess(false);
                            setIsLoading(false);
                            setLogError(error.response.data.message);
                        });
                } catch (error) {
                    console.log('error signing up:', error);
                    setSuccess(false);
                    setIsLoading(false);
                    setLogError(error.message);
                }
            }
        } else {
            setLogError('Fill in all the fields');
        }
    }

    async function pageLogin(nextPage) {
        try {
            setIsLoading(true);
            setLogError('');
            let userIn = await login(email, password);

            API.get(process.env.REACT_APP_API_ENDPOINT_NAME, '/user/registration')
                .then((response) => {
                    console.log(response, 'registration response');
                    if (nextPage === 'pro') {
                        window.location.replace(process.env.REACT_APP_PRESENTER_URL);
                    } else {
                        history.push('/user/home');
                    }
                })
                .catch((error) => {
                    setIsLoading(false);
                    setLogError(error.message);
                    throw error;
                });
        } catch (error) {
            console.log('error signing up:', error);
            setIsLoading(false);
            setLogError(error.message);
        }
    }

    return (
        <Form>
            {logError ? <p style={{ color: 'red', textAlign: 'center' }}>{logError}</p> : null}
            {logSuccess ? (
                <p style={{ color: '#01539d', textAlign: 'center' }}>{logSuccess}</p>
            ) : null}
            {!success ? (
                <>
                    <Input
                        type="text"
                        style={{
                            borderColor: logError && name.length === 0 ? 'red' : ''
                        }}
                        required
                        placeholder="First name"
                        value={name}
                        onChange={nameChange}
                    />
                    <Input
                        type="text"
                        style={{
                            borderColor: logError && familyName.length === 0 ? 'red' : ''
                        }}
                        required
                        placeholder="Last name"
                        value={familyName}
                        onChange={familyNameChange}
                    />
                    <Input
                        type="text"
                        style={{
                            borderColor: logError && occupation.length === 0 ? 'red' : ''
                        }}
                        required
                        placeholder="Occupation"
                        value={occupation}
                        onChange={occupationChange}
                    />
                    <Input
                        type="email"
                        style={{
                            borderColor: logError && email.length === 0 ? 'red' : ''
                        }}
                        disabled
                        placeholder="Email"
                        value={email}
                    />
                    <Input
                        type="password"
                        style={{
                            borderColor: logError && password.length < 8 ? 'red' : ''
                        }}
                        required
                        placeholder="Password"
                        value={password}
                        onChange={passwordChange}
                    />
                    <p
                        style={pwdError ? { color: 'red' } : {}}
                        tw="mt-6 text-xs text-gray-800 text-center">
                        Required: Password length at least 8 characters. <br /> A lowercase letter,
                        an uppercase letter, a number.
                    </p>
                    <SubmitButton type="submit" onClick={signUp}>
                        {isLoading ? (
                            <div className="lds-ring">
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                            </div>
                        ) : (
                            <span className="text">{submitButtonText}</span>
                        )}
                    </SubmitButton>
                    <p tw="mt-6 text-xs text-gray-600 text-center">
                        I agree to abide by Beaker health's{' '}
                        <a href={tosUrl} tw="border-b border-gray-500 border-dotted">
                            Terms of Service
                        </a>{' '}
                        and its{' '}
                        <a href={privacyPolicyUrl} tw="border-b border-gray-500 border-dotted">
                            Privacy Policy
                        </a>
                    </p>
                </>
            ) : (
                <div style={{ textAlign: 'center' }}>
                    <RingLoader />
                </div>
            )}
        </Form>
    );
};

export default ({ logoLinkUrl = '/' }) => (
    <AnimationRevealPage>
        <Container>
            <Content>
                <MainContainer>
                    <LogoLink href={logoLinkUrl}>
                        <LogoImage src={logo} />
                    </LogoLink>
                    <FormContainer>
                        <FormSignup />
                    </FormContainer>
                </MainContainer>
            </Content>
        </Container>
    </AnimationRevealPage>
);
