import * as actionTypes from '../actions/presentations/actionTypes';
import { updateObject } from 'shared/utils/object';

const initialState = {
    currentPresentationToView: {
        loading: false,
        success: false,
        error: null,
        data: null
    },
    submitPresentationFeedback: {
        loading: false,
        success: false,
        error: null,
        data: null
    },
    currentPresentationInfoToView: {
        loading: false,
        success: false,
        error: null,
        data: null
    },
    userPresentations: {
        loading: false,
        success: false,
        error: null,
        data: null
    }
};

const getUserPresentationsStart = (state, action) => {
    const newState = updateObject(state, {
        userPresentations: updateObject(state.userPresentations, {
            loading: true,
            error: null,
            success: false,
            data: null
        })
    });
    return newState;
};

const getUserPresentationsSuccess = (state, action) => {
    const newState = updateObject(state, {
        userPresentations: updateObject(state.userPresentations, {
            loading: false,
            error: null,
            success: true,
            data: action.payload
        })
    });
    return newState;
};

const getUserPresentationsFail = (state, action) => {
    const newState = updateObject(state, {
        userPresentations: updateObject(state.userPresentations, {
            loading: false,
            error: action.payload.error,
            success: false,
            data: null
        })
    });
    return newState;
};

const getPresentationStart = (state, action) => {
    const newState = updateObject(state, {
        currentPresentationToView: updateObject(
            state.currentPresentationToView,
            {
                loading: true,
                error: null,
                success: false,
                data: null
            }
        )
    });
    return newState;
};

const getPresentationSuccess = (state, action) => {
    const newState = updateObject(state, {
        currentPresentationToView: updateObject(
            state.currentPresentationToView,
            {
                loading: false,
                error: null,
                success: true,
                data: action.payload
            }
        )
    });
    return newState;
};

const getPresentationFail = (state, action) => {
    const newState = updateObject(state, {
        currentPresentationToView: updateObject(
            state.currentPresentationToView,
            {
                loading: false,
                error: action.payload.error,
                success: false,
                data: null
            }
        )
    });
    return newState;
};

const getPresentationReset = (state, action) => {
    const newState = updateObject(state, {
        currentPresentationToView: updateObject(
            state.currentPresentationToView,
            {
                loading: false,
                error: null,
                success: false,
                data: null
            }
        )
    });
    return newState;
};

const submitPresentationFeedbackStart = (state, action) => {
    const newState = updateObject(state, {
        submitPresentationFeedback: updateObject(
            state.submitPresentationFeedback,
            {
                loading: true,
                error: null,
                success: false,
                data: null
            }
        )
    });
    return newState;
};

const submitPresentationFeedbackSuccess = (state, action) => {
    const newState = updateObject(state, {
        submitPresentationFeedback: updateObject(
            state.submitPresentationFeedback,
            {
                loading: false,
                error: null,
                success: true,
                data: action.payload
            }
        )
    });
    return newState;
};

const submitPresentationFeedbackFail = (state, action) => {
    const newState = updateObject(state, {
        submitPresentationFeedback: updateObject(
            state.submitPresentationFeedback,
            {
                loading: false,
                error: action.payload.error,
                success: false,
                data: null
            }
        )
    });
    return newState;
};

const submitPresentationFeedbackReset = (state, action) => {
    const newState = updateObject(state, {
        submitPresentationFeedback: updateObject(
            state.submitPresentationFeedback,
            {
                loading: false,
                error: false,
                success: false,
                data: null
            }
        )
    });
    return newState;
};

const getPresentationInfoStart = (state, action) => {
    const newState = updateObject(state, {
        currentPresentationInfoToView: updateObject(
            state.currentPresentationInfoToView,
            {
                loading: true,
                error: null,
                success: false,
                data: null
            }
        )
    });
    return newState;
};

const getPresentationInfoSuccess = (state, action) => {
    const newState = updateObject(state, {
        currentPresentationInfoToView: updateObject(
            state.currentPresentationInfoToView,
            {
                loading: false,
                error: null,
                success: true,
                data: action.payload
            }
        )
    });
    return newState;
};

const getPresentationInfoFail = (state, action) => {
    const newState = updateObject(state, {
        currentPresentationInfoToView: updateObject(
            state.currentPresentationInfoToView,
            {
                loading: false,
                error: action.payload.error,
                success: false,
                data: null
            }
        )
    });
    return newState;
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_PRESENTATION_START:
            return getPresentationStart(state, action);
        case actionTypes.GET_PRESENTATION_SUCCESS:
            return getPresentationSuccess(state, action);
        case actionTypes.GET_PRESENTATION_FAIL:
            return getPresentationFail(state, action);
        case actionTypes.GET_PRESENTATION_RESET:
            return getPresentationReset(state, action);

        case actionTypes.SUBMIT_PRESENTATION_FEEDBACK_START:
            return submitPresentationFeedbackStart(state, action);
        case actionTypes.SUBMIT_PRESENTATION_FEEDBACK_SUCCESS:
            return submitPresentationFeedbackSuccess(state, action);
        case actionTypes.SUBMIT_PRESENTATION_FEEDBACK_FAIL:
            return submitPresentationFeedbackFail(state, action);
        case actionTypes.SUBMIT_PRESENTATION_FEEDBACK_RESET:
            return submitPresentationFeedbackReset(state, action);

        case actionTypes.GET_PRESENTATION_INFO_START:
            return getPresentationInfoStart(state, action);
        case actionTypes.GET_PRESENTATION_INFO_SUCCESS:
            return getPresentationInfoSuccess(state, action);
        case actionTypes.GET_PRESENTATION_INFO_FAIL:
            return getPresentationInfoFail(state, action);

        case actionTypes.GET_USER_PRESENTATIONS_START:
            return getUserPresentationsStart(state, action);
        case actionTypes.GET_USER_PRESENTATIONS_SUCCESS:
            return getUserPresentationsSuccess(state, action);
        case actionTypes.GET_USER_PRESENTATIONS_FAIL:
            return getUserPresentationsFail(state, action);

        default:
            return state;
    }
};

export default reducer;
