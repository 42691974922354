import React from 'react';
import { Auth } from 'aws-amplify';
import LogRocket from 'logrocket';

export const UserContext = React.createContext(null);

export const UserProvider = ({ children }) => {
    const [user, setUser] = React.useState(null);
    const [isLoaded, setIsLoaded] = React.useState(false);

    React.useEffect(() => {
        Auth.currentSession()
            .then((data) => {
                Auth.currentAuthenticatedUser({
                    bypassCache: true // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
                })
                    .then((user) => {
                        setUser(user);
                        setIsLoaded(true);
                    })
                    .catch(() => {
                        setUser(null);
                        setIsLoaded(true);
                    });
            })
            .catch((err) => {
                console.log(err);
                setIsLoaded(true);
            });
    }, []);

    const login = (usernameOrEmail, password) =>
        Auth.signIn(usernameOrEmail, password)
            .then((cognitoUser) => {
                setUser(cognitoUser);
                LogRocket.identify(cognitoUser.username);
            })
            .catch((err) => {
                if (err.code === 'UserNotFoundException') {
                    err.message = 'Invalid username or password';
                }

                // ... (other checks)

                throw err;
            });

    // same thing here
    const logout = () => {
        try {
            Auth.signOut().then((data) => {
                setUser(null);
                return data;
            });
        } catch (err) {
            throw err;
        }
    };

    const isAdmin = () => {
        return checkForGroup('admins');
    };

    const isPresenter = () => {
        if (user) {
            const zoneinfo = user.signInUserSession.idToken.payload['zoneinfo'];
            if (!zoneinfo) {
                return true;
            } else {
                return checkForGroup('presenters');
            }
        }
    };

    const checkForGroup = (groupName) => {
        if (user) {
            const userGroup = user.signInUserSession.accessToken.payload['cognito:groups'];
            const zoneinfo = user.signInUserSession.idToken.payload['zoneinfo'];
            const checkGroupName = `$${zoneinfo}_${groupName}`;
            if (userGroup && userGroup.length > 0 && zoneinfo && zoneinfo.length > 0) {
                const result = userGroup.filter((item) => item.includes(checkGroupName));
                if (result.length === 1) {
                    return true;
                } else {
                    return false;
                }
            } else {
                return false;
            }
        } else {
            return false;
        }
    };

    const values = React.useMemo(
        () => ({ user, login, logout, isAdmin, isLoaded, isPresenter }),
        [user, isLoaded]
    );

    return <UserContext.Provider value={values}>{children}</UserContext.Provider>;
};

export const useUser = () => {
    const context = React.useContext(UserContext);

    if (context === undefined) {
        throw new Error('`useUser` hook must be used within a `UserProvider` component');
    }

    return context;
};
