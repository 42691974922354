import * as actionTypes from './actionTypes';
import { API } from 'aws-amplify';
import axios from 'axios';

export const getUserProfileStart = () => {
    return {
        type: actionTypes.GET_USER_PROFILE_START
    };
};

export const getUserProfileSuccess = (data) => {
    return {
        type: actionTypes.GET_USER_PROFILE_SUCCESS,
        payload: data
    };
};

export const getUserProfileFail = (error) => {
    return {
        type: actionTypes.GET_USER_PROFILE_FAIL,
        payload: { error }
    };
};

export const onSaveUserProfile = (profileImage, userProfile) => {
    return (dispatch, getState) => {
        dispatch(onSaveUserProfilePicture(profileImage, userProfile));
    };
};

// Update user profile details
export const onSaveUserProfilePictureStart = () => {
    return {
        type: actionTypes.ON_SAVE_USER_PROFILE_PICTURE_START
    };
};

export const onSaveUserProfilePictureSuccess = (data) => {
    return {
        type: actionTypes.ON_SAVE_USER_PROFILE_PICTURE_SUCCESS,
        payload: data
    };
};

export const onSaveUserProfilePictureFail = (error) => {
    return {
        type: actionTypes.ON_SAVE_USER_PROFILE_PICTURE_FAIL,
        payload: { error }
    };
};

export const onSaveUserProfilePicture = (profileImage, userProfile) => {
    return (dispatch, getState) => {
        dispatch(onSaveUserProfilePictureStart());
        const state = getState();
        const { user } = state;
        const userId = user.userProfile.data.id;
        const pictureUrlToUpdate = `users/${userId}/picture/profile.jpg`;
        try {
            API.get(
                process.env.REACT_APP_API_ENDPOINT_NAME,
                `/user/picture-upload?contentType=${profileImage.type}`
            )
                .then((signedURLResponse) => {
                    fetch(signedURLResponse, {
                        method: 'PUT',
                        body: profileImage,
                        headers: {
                            'Content-Type': profileImage.type
                        }
                    })
                        .then((response) => {
                            dispatch(
                                onSaveUserProfileInfo(userProfile, {
                                    picture: pictureUrlToUpdate
                                })
                            );
                            dispatch(
                                onSaveUserProfilePictureSuccess(profileImage)
                            );
                        })
                        .catch((error) => {
                            dispatch(onSaveUserProfilePictureFail(error));
                            dispatch(onSaveUserProfileInfo(userProfile));
                        });
                })
                .catch((signedURLError) => {
                    dispatch(onSaveUserProfilePictureFail(signedURLError));
                    dispatch(onSaveUserProfileInfo(userProfile));
                });
        } catch (error) {
            dispatch(onSaveUserProfilePictureFail(error));
            dispatch(onSaveUserProfileInfo(userProfile));
        }
    };
};

export const onSaveUserProfileInfoStart = () => {
    return {
        type: actionTypes.ON_SAVE_USER_PROFILE_INFO_START
    };
};

export const onSaveUserProfileInfoSuccess = (data) => {
    return {
        type: actionTypes.ON_SAVE_USER_PROFILE_INFO_SUCCESS,
        payload: data
    };
};

export const onSaveUserProfileInfoFail = (error) => {
    return {
        type: actionTypes.ON_SAVE_USER_PROFILE_INFO_FAIL,
        payload: { error }
    };
};

export const onSaveUserProfileInfo = (
    userProfile,
    updatedUserProfileData = {}
) => {
    return (dispatch, getState) => {
        dispatch(onSaveUserProfileInfoStart());

        const data = {
            title: userProfile.title,
            credentials: userProfile.credentials,
            first_name: userProfile.first_name,
            last_name: userProfile.last_name,
            occupation: userProfile.occupation,
            linkedin_url: userProfile.linkedin_url,
            twitter_url: userProfile.twitter_url,
            fb_url: userProfile.fb_url,
            website_url: userProfile.website_url,
            about_me: userProfile.about_me,
            ...updatedUserProfileData
        };

        return API.post(process.env.REACT_APP_API_ENDPOINT_NAME, '/user/info', {
            body: data
        })
            .then((response) => {
                dispatch(onSaveUserProfileInfoSuccess(response));
            })
            .catch((error) => {
                dispatch(onSaveUserProfileInfoFail(error));
            });
    };
};

export const getUserProfile = (id) => {
    return (dispatch, getState) => {
        dispatch(getUserProfileStart());

        API.get(process.env.REACT_APP_API_ENDPOINT_NAME, '/user/info')
            .then((response) => {
                dispatch(getUserProfileSuccess(response));
            })
            .catch((error) => {
                console.log(error.response);
                dispatch(getUserProfileFail(error));
            });
    };
};

// Get user certificate
export const getCertificateForUserPresentationStart = () => {
    return {
        type: actionTypes.ON_GET_CERTIFICATE_FOR_USER_PRESENTATION_START
    };
};

export const getCertificateForUserPresentationSuccess = (data) => {
    return {
        type: actionTypes.ON_GET_CERTIFICATE_FOR_USER_PRESENTATION_SUCCESS,
        payload: data
    };
};

export const getCertificateForUserPresentationFail = (error) => {
    return {
        type: actionTypes.ON_GET_CERTIFICATE_FOR_USER_PRESENTATION_FAIL,
        payload: { error }
    };
};

export const getCertificateForUserPresentationReset = () => {
    return {
        type: actionTypes.ON_GET_CERTIFICATE_FOR_USER_PRESENTATION_RESET
    };
};

export const getCertificateForUserPresentation = (staticData) => {
    return (dispatch, getState) => {
        dispatch(getCertificateForUserPresentationStart());
        dispatch(getCertificateForUserPresentationSuccess(staticData));
    };
};
