import React, { useEffect, useState } from 'react';
import { Fragment } from 'react';
import StarRatings from 'react-star-ratings';
import { useHistory, useLocation } from 'react-router-dom';
import { API } from 'aws-amplify';
import Topbar from 'components/headers/Topbar/Topbar.js';
import Footer from 'components/footers/UserFooter';
import styles from './PresentationPage.module.css';
import ReactModalAdapter from 'helpers/ReactModalAdapter';
import styled from 'styled-components';
import tw from 'twin.macro';
import { LongText } from '../../components/shared/UI';
import { useParams } from 'react-router-dom';
import { RatingOverview } from '../../components/shared/UI';
import { getPresentationInfo } from 'state/actions';
import { getUserProfile } from 'state/actions/user/user';
import { useDispatch, useSelector } from 'react-redux';
import { IoMdCloseCircleOutline } from 'react-icons/io';
import { FaFacebookF, FaLinkedinIn, FaTwitter } from 'react-icons/fa';
import { FaRegCheckCircle } from 'react-icons/fa';
import { MdMailOutline } from 'react-icons/md';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { EmailShareButton } from 'react-share';
import { FacebookShareButton } from 'react-share';
import { LinkedinShareButton } from 'react-share';
import { TwitterShareButton } from 'react-share';
import { useUser } from 'util/auth.js';
import { MdAccountCircle } from 'react-icons/md';
import { RingLoader } from 'components/shared/UI';
import { NoResult } from 'components/shared/UI';
import { uncacheImageURL } from 'shared/utils/cache';
import EmbededVideoPlayer from 'helpers/EmbededVideoPlayer';
import CertificateApprovedRibbon from 'images/certificate/certificate-approved.svg';
import { ON_LOGIN_REDIRECT_URL } from 'shared/utils/constants';
import { atom, useAtom } from 'jotai';

const StyledModal = styled(ReactModalAdapter)`
    &.SharePresentationModal__overlay {
        ${tw`fixed inset-0 z-50`}
        transition: opacity 2000ms ease-in-out;
        background: rgba(0, 0, 0, 0.3);
    }
    &.SharePresentationModal__content {
        ${tw`xl:mx-auto sm:m-auto max-h-1/2 justify-self-start lg:w-5/12 2xl:w-1/3 absolute inset-0 flex items-start items-center self-start justify-center max-w-screen-xl px-10 pb-10 m-auto my-auto bg-white rounded-lg outline-none`}
        height: fit-content;
    }
    .content {
        ${tw`lg:p-16 w-full`}
    }
`;

const VideoPreviewModal = styled(ReactModalAdapter)`
    &.VideoPreviewModal__overlay {
        ${tw`fixed inset-0 z-50`}
        transition: opacity 2000ms ease-in-out;
        background: rgba(0, 0, 0, 0.3);
    }
    &.VideoPreviewModal__content {
        ${tw`xl:mx-auto sm:m-auto justify-self-start absolute inset-0 flex flex-col items-start items-center self-start justify-center w-1/2 max-w-xl px-5 pb-10 m-auto my-auto bg-white rounded-lg outline-none`}
        height: fit-content;
    }
    .content {
        ${tw`lg:p-16 w-full`}
    }
`;

const ObjectiveCard = ({ text }) => {
    return (
        <li className="grid grid-cols-12">
            <span className="col-end- col-start-1 pt-2">
                <img
                    alt="Check Mark"
                    src={require(`../../images/sample/presentation/checkmark.png`)}></img>
            </span>
            <p className="lg:col-start-2 col-start-3 col-end-13 break-words">{text}</p>
        </li>
    );
};

export const courseModules = atom(null);

const PresentationPage = (props) => {
    const dispatch = useDispatch();
    const [shareLinkCopied, setShareLinkCopied] = useState(false);
    const [presentation, setPresentation] = useState({});
    const [presenter, setPresenter] = useState({});
    const [modules, setModules] = useAtom(courseModules);
    const [type, setType] = useState('module');
    const [isShareModalActive, setIsShareModalActive] = useState(false);
    const [isAwardCEModalActive, setIsAwardCEModalActive] = useState(false);
    const [isOnFinishModalActive, setIsOnFinishModalActive] = useState(false);
    const [showBuyOption, setShowBuyOption] = useState(false);
    const [nextId, setNextId] = useState(null);
    const [isPresenterProfileImageError, setIsPresenterProfileImageError] = useState(false);
    const [isFeaturedProfileImageError, setIsFeaturedProfileImageError] = useState(false);
    const [isVideoPreviewModalActive, setIsVideoPreviewModalActive] = useState(false);
    const currentPresentationInfoState = useSelector(
        ({ presentations }) => presentations.currentPresentationInfoToView
    );
    const [userLoading, setUserLoading] = useState(false);
    const userProfileState = useSelector((state) => state.user.userProfile);
    const { orgName, id } = useParams();
    const history = useHistory();
    const { search } = useLocation();
    const searchParams = new URLSearchParams(search);
    const { user, isAdmin } = useUser();

    useEffect(() => {
        dispatch(getPresentationInfo(id));
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const { success, loading } = userProfileState;
        if (loading) setUserLoading(true);
        if (success && !loading) {
            setUserLoading(false);
            const { data } = userProfileState;
            if (presentation['priceId']) {
                setShowBuyOption(true);
            }
            if (data.paidItems) {
                if (data.paidItems.indexOf(presentation['priceId']) !== -1) {
                    setShowBuyOption(false);
                }
            }
            if (data.progress) {
                const found = data.progress.find((item) => item.courseId === id);
                if (found) {
                    if (found.nextId) {
                        setNextId(found.nextId);
                    }
                }
            }
        }
    }, [userProfileState]);

    useEffect(() => {
        const { success, loading } = currentPresentationInfoState;
        if (success && !loading) {
            const {
                data: { Item, presenter }
            } = currentPresentationInfoState;
            setPresentation(Item);
            setPresenter(presenter);
            if (Item['modules']) {
                setModules(Item['modules']);
            }
            if (Item['type']) {
                setType(Item['type']);
            }
            if (user && Item['priceId']) {
                dispatch(getUserProfile());
            }
            if (searchParams.get('success')) {
                setShowBuyOption(false);
            }
        }
    }, [currentPresentationInfoState]);

    let subHeadingText;
    let references;
    let featuredReview;

    const thumbnailSrc =
        process.env.REACT_APP_PRESENTATION_THUMBNAILS_URL +
        '/thumbnails/presentations/' +
        id +
        '/thumbnail.jpeg';

    if (presentation.categories) {
        subHeadingText = presentation.categories[0];
    }

    let presenterAvatar;
    if (presenter.picture) {
        presenterAvatar = uncacheImageURL(
            process.env.REACT_APP_PROFILE_IMAGES_URL + '/' + presenter.picture
        );
    } else {
        presenterAvatar = '../../images/sample/presentation/presentation-hero-image.png';
    }

    //primaryButtonText="Sign Up To View"
    //primaryButtonUrl="/signup"
    const viewBtnText = 'View Module';
    const signupBtnText = 'Sign up to view';
    const buyBtnText = 'Buy to view';

    let description = '';

    if (presentation.description) {
        description = presentation.description;
    }

    if (presentation.featuredReview) {
        featuredReview = presentation.featuredReview;
        featuredReview.user.picture =
            process.env.REACT_APP_PROFILE_IMAGES_URL + '/' + featuredReview.user.picture;
    }

    if (presentation.referenceFiles && presentation.referenceFiles.length > 0) {
        references = presentation.referenceFiles;
    }

    const ReferenceFileList = (props) => {
        const refs = props.refs;
        const list = refs.map((ref) => (
            <li key={ref.fileId} className={`${styles.ReferenceFileLink} text-l font-semibold`}>
                <a href={ref.fileKey} target="_blank" rel="noopener noreferrer">
                    {ref.fileName}
                </a>
            </li>
        ));
        return <ul>{list}</ul>;
    };

    const imageError = (evt) => {
        evt.target.src = require(`../../images/sample/presentation/presentation-hero-image.png`);
    };

    const presenterProfileImageError = () => {
        setIsPresenterProfileImageError(true);
    };

    const featuredProfileImageError = () => {
        setIsFeaturedProfileImageError(true);
    };

    const getSharePresentationLink = () => {
        return `${process.env.REACT_APP_ROOT_URL}/presentation/${id}`;
    };

    const approveCE = (id) => {
        const data = {
            body: { test: 'text' }
        };
        // var q = Date.now();
        API.post(process.env.REACT_APP_API_ENDPOINT_NAME, `/approvece/${id}`, data)
            .then((response) => {
                if (response.result === 'OK') {
                    dispatch(getPresentationInfo(id));
                    setIsAwardCEModalActive(false);
                    setIsOnFinishModalActive(true);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const shareModal = (
        <StyledModal
            closeTimeoutMS={300}
            className={`SharePresentationModal`}
            isOpen={isShareModalActive}
            onRequestClose={() => {
                setIsShareModalActive(false);
            }}
            shouldCloseOnOverlayClick={true}>
            <div className="flex flex-col w-full">
                <div className="flex items-center justify-between w-full py-2">
                    <span className={`font-semibold text-2xl ${styles.ShareModalHeader}`}>
                        Share
                    </span>
                    <IoMdCloseCircleOutline
                        className="cursor-pointer"
                        size={'1.5em'}
                        color={'#283E59'}
                        onClick={() => {
                            setShareLinkCopied(false);
                            setIsShareModalActive(false);
                        }}
                    />
                </div>

                <div
                    className={`flex items-center justify-between space-x-5 mt-3 ${styles.IconColor}`}>
                    <div className="flex-col-1 flex justify-center cursor-pointer">
                        <EmailShareButton
                            url={getSharePresentationLink()}
                            className="hover:outline-none focus:outline-none outline-none">
                            <div
                                className={`border-4 rounded-full p-4 flex justify-center items-center sm:w-16 sm:h-16 xl:w-20 xl:h-20 ${styles.ShareModalIcon}`}>
                                <MdMailOutline className="sm:text-3xl xl:text-4xl" />
                            </div>
                            <span className="text-lg font-semibold text-center">Email</span>
                        </EmailShareButton>
                    </div>

                    <div className="flex-col-1 flex justify-center cursor-pointer">
                        <FacebookShareButton
                            url={getSharePresentationLink()}
                            className="hover:outline-none focus:outline-none outline-none">
                            <div
                                className={`border-4 rounded-full p-4 flex justify-center items-center sm:w-16 sm:h-16 xl:w-20 xl:h-20 ${styles.ShareModalIcon}`}>
                                <FaFacebookF className="sm:text-3xl xl:text-4xl" />
                            </div>
                            <span className="text-lg font-semibold text-center">Facebook</span>
                        </FacebookShareButton>
                    </div>
                    <div className="flex-col-1 flex justify-center cursor-pointer">
                        <TwitterShareButton
                            url={getSharePresentationLink()}
                            className="hover:outline-none focus:outline-none outline-none">
                            <div
                                className={`border-4 rounded-full p-4 flex justify-center items-center sm:w-16 sm:h-16 xl:w-20 xl:h-20 ${styles.ShareModalIcon}`}>
                                <FaTwitter className="sm:text-3xl xl:text-4xl" />
                            </div>
                            <span className="text-lg font-semibold text-center">Twitter</span>
                        </TwitterShareButton>
                    </div>

                    <div className="flex-col-1 flex justify-center cursor-pointer">
                        <LinkedinShareButton
                            url={getSharePresentationLink()}
                            className="hover:outline-none focus:outline-none outline-none">
                            <div
                                className={`border-4 rounded-full p-4 flex justify-center items-center sm:w-16 sm:h-16 xl:w-20 xl:h-20 ${styles.ShareModalIcon}`}>
                                <FaLinkedinIn className="sm:text-3xl xl:text-4xl" />
                            </div>
                            <span className="text-lg font-semibold text-center">LinkedIn</span>
                        </LinkedinShareButton>
                    </div>
                </div>

                <div className="justify-center w-full mt-5 text-center">
                    <div className={`p-3 w-full flex justify-between ${styles.CopyTextContainer}`}>
                        <span className={`${styles.CopyText} mr-1 text-lg font-semibold`}>
                            {getSharePresentationLink()}
                        </span>
                        <CopyToClipboard
                            text={getSharePresentationLink()}
                            onCopy={() => copyLinkToSharePresentation()}>
                            <button className={`outline-none ${styles.CopyButton}`}>Copy</button>
                        </CopyToClipboard>
                    </div>
                </div>
                {shareLinkCopied ? <div className="w-full mt-2">Link copied!</div> : null}
            </div>
        </StyledModal>
    );

    const awardCEModal = (
        <StyledModal
            closeTimeoutMS={300}
            className={`SharePresentationModal`}
            isOpen={isAwardCEModalActive}
            onRequestClose={() => {
                setIsAwardCEModalActive(false);
            }}
            shouldCloseOnOverlayClick={true}>
            <div className="absolute top-0 left-0 flex justify-end w-full p-4">
                <IoMdCloseCircleOutline
                    className="cursor-pointer"
                    size={'2em'}
                    color={'#283E59'}
                    onClick={() => {
                        setIsAwardCEModalActive(false);
                    }}
                />
            </div>

            <div className="flex flex-col self-center justify-center w-full min-h-full px-4">
                <div className="flex items-center justify-center w-full mt-5 text-center"></div>

                <div className="justify-center w-full mt-5 text-center">
                    <h2 className={`font-semibold text-4xl ${styles.OnFinishModalHeader}`}>
                        Are you sure?
                    </h2>
                </div>

                <div className="justify-center w-full mt-5 text-center">
                    <p className={`font-normal text-xl ${styles.OnFinishModalPara}`}>
                        Approving this presentation will give it a CE ribbon so scholars can earn CE
                        credits upon completion.
                    </p>
                </div>

                <div className="justify-center w-full mt-5 mb-5 text-center">
                    <button
                        className="bg-yellow sm:px-5 inline-block py-1 mr-5 text-xl font-bold text-white rounded cursor-pointer"
                        onClick={() => {
                            setIsAwardCEModalActive(false);
                        }}>
                        No
                    </button>
                    <button
                        className="bg-yellow sm:px-5 inline-block py-1 mr-5 text-xl font-bold text-white rounded cursor-pointer"
                        onClick={() => {
                            approveCE(id);
                        }}>
                        Yes, I'm sure
                    </button>
                </div>
            </div>
        </StyledModal>
    );

    const onFinishModal = (
        <StyledModal
            closeTimeoutMS={300}
            className={`SharePresentationModal`}
            isOpen={isOnFinishModalActive}
            onRequestClose={() => {
                setIsOnFinishModalActive(false);
            }}
            shouldCloseOnOverlayClick={true}>
            <div className="absolute top-0 left-0 flex justify-end w-full p-4">
                <IoMdCloseCircleOutline
                    className="cursor-pointer"
                    size={'2em'}
                    color={'#283E59'}
                    onClick={() => {
                        setIsOnFinishModalActive(false);
                    }}
                />
            </div>

            <div className="flex flex-col self-center justify-center w-full min-h-full px-4">
                <div className="flex items-center justify-center w-full mt-5 text-center">
                    <FaRegCheckCircle size={'6em'} color={'#F9B041'} />
                </div>

                <div className="justify-center w-full mt-5 text-center">
                    <h2 className={`font-semibold text-4xl ${styles.OnFinishModalHeader}`}>
                        Well Done!
                    </h2>
                </div>

                <div className="justify-center w-full mt-5 text-center">
                    <p className={`font-normal text-xl ${styles.OnFinishModalPara}`}>
                        You have granted CE certification for this presentation
                    </p>
                </div>

                <div className="justify-center w-full mt-5 text-center">
                    <button
                        className="bg-yellow sm:px-5 inline-block py-1 mr-5 text-xl font-bold text-white rounded cursor-pointer"
                        onClick={() => {
                            setIsOnFinishModalActive(false);
                        }}>
                        Close
                    </button>
                </div>
            </div>
        </StyledModal>
    );

    const videoPreviewModal = (
        <VideoPreviewModal
            closeTimeoutMS={300}
            className={`VideoPreviewModal`}
            isOpen={isVideoPreviewModalActive}
            onRequestClose={() => {
                setIsVideoPreviewModalActive(false);
            }}
            shouldCloseOnOverlayClick={true}>
            <div className="flex justify-end w-full">
                <ul className={`${styles.VideoPreviewHeader} pt-2`}>
                    <li>
                        <h4 className={`font-semibold text-2xl ${styles.ShareModalHeader}`}>
                            Preview
                        </h4>
                    </li>
                    <li>
                        <IoMdCloseCircleOutline
                            className="cursor-pointer"
                            size={'1.5em'}
                            color={'#283E59'}
                            onClick={() => {
                                setIsVideoPreviewModalActive(false);
                            }}
                        />
                    </li>
                </ul>
            </div>

            <div className="flex flex-col w-full">
                <div className={`flex items-center justify-between space-x-5 mt-1`}>
                    <div className="flex flex-col justify-center w-full cursor-pointer">
                        <EmbededVideoPlayer
                            url={presentation.video_url}
                            tw="w-full"
                            defaultPaddingBottom="75%"
                            endTime="90"
                        />
                    </div>
                </div>
            </div>
        </VideoPreviewModal>
    );

    const copyLinkToSharePresentation = () => {
        setShareLinkCopied(true);
    };

    const onWatchPresentation = (id) => {
        if (type === 'COURSE') {
            if (nextId) {
                history.push(`/course/${id}/module/view/${nextId}`);
            } else {
                history.push(`/course/${id}/module/view/${modules[1]}`);
            }
        } else {
            history.push(`/presentation/view/${id}`);
        }
    };

    const buyPresentation = (id) => {
        //stripe buy course/module

        const data = {
            body: {
                priceId: presentation.priceId,
                mode: 'payment',
                success_url: `${window.location}?success=true`,
                cancel_url: `${window.location}?success=false`
            }
        };

        API.post(process.env.REACT_APP_API_ENDPOINT_NAME, `/stripe/checkout`, data)
            .then((response) => {
                window.location = response.session;
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const signUp = () => {
        //history.push('/pricing');
        localStorage.setItem(ON_LOGIN_REDIRECT_URL, window.location.href);
        if (orgName) {
            history.push(`/login/${orgName}`);
        } else {
            history.push('/signup');
            //window.location = process.env.REACT_APP_LANDING_URL + '/pricing';
        }
    };

    const { success, loading, error } = currentPresentationInfoState;

    let containerUI = (
        <div className={`flex justify-center items-center`} style={{ height: '70%' }}>
            <RingLoader />
        </div>
    );

    if (!loading && !userLoading && success) {
        containerUI = (
            <Fragment>
                <div
                    className={`md:flex justify-between px-8 sm:px-16 py-8 relative ${styles.HeroBackground}`}>
                    {presentation.approvedCE ? (
                        <img
                            className={`${styles.CertificateApprovedRibbon}`}
                            src={CertificateApprovedRibbon}
                            alt="Certificate Ribbon"
                        />
                    ) : null}

                    <div className={`w-4/6 lg:w-2/6 mb-5 lg:mb-0 ${styles.HeroImageContainer}`}>
                        <img
                            className="w-full"
                            alt="Presentation Thumbnail"
                            src={thumbnailSrc}
                            onError={imageError}></img>
                        {presentation.video_url ? (
                            <a
                                href="#modal"
                                className={`inline-block py-1 sm:px-5 font-bold text-xl text-white mr-5 cursor-pointer ${styles.PreviewButton}`}
                                onClick={() => setIsVideoPreviewModalActive(true)}>
                                Preview this Presentation
                            </a>
                        ) : null}
                    </div>

                    <div className="md:w-4/6 lg:w-4/6 md:px-10">
                        <p className="text-yellow text-xl break-words">{subHeadingText}</p>
                        <h1 className="md:text-4xl text-2xl font-semibold text-white break-words">
                            {presentation.title}
                        </h1>
                        <p className="text-lg text-white break-words">{presentation.purpose}</p>

                        <div className="flex-start lg:w-1/2 md:w-3/4 flex items-center w-full pt-2">
                            <div className="w-16 h-16 mr-4 overflow-hidden rounded-full">
                                {!isPresenterProfileImageError ? (
                                    <img
                                        className="object-cover w-full h-full"
                                        alt="Educator"
                                        src={presenterAvatar}
                                        onError={presenterProfileImageError}></img>
                                ) : (
                                    <MdAccountCircle color="F9B041" size={'4em'} />
                                )}
                            </div>
                            <div className="lg:w-3/4 w-4/5">
                                <div className="flex items-center">
                                    <span className="inline-block mr-2">
                                        <img
                                            src={require(`../../images/star-rating.png`)}
                                            alt="Star Rating"
                                            className=""></img>
                                    </span>
                                    <span className="text-yellow mr-2">
                                        {presenter.reviewAvgRating &&
                                            presenter.reviewAvgRating.toString().slice(0, 3)}
                                    </span>
                                    <span className="text-white">
                                        ({presenter.reviewCount} ratings)
                                    </span>
                                </div>

                                <p className="text-white">
                                    Created by{' '}
                                    <span className="text-yellow">
                                        {presenter.first_name} {presenter.last_name}
                                    </span>
                                </p>
                            </div>
                        </div>

                        <div className="flex-start flex mt-5">
                            {showBuyOption && user ? (
                                <a
                                    href="#modal"
                                    className="bg-yellow sm:px-5 sm:py-1 inline-block p-2 mr-5 text-xl font-bold text-white rounded cursor-pointer"
                                    onClick={() => buyPresentation(id)}>
                                    {buyBtnText}
                                </a>
                            ) : (
                                <>
                                    {user ? (
                                        <a
                                            href="#modal"
                                            className="bg-yellow sm:px-5 sm:py-1 inline-block p-2 mr-5 text-xl font-bold text-white rounded cursor-pointer"
                                            onClick={() => onWatchPresentation(id)}>
                                            {viewBtnText}
                                        </a>
                                    ) : (
                                        <a
                                            href="#signup"
                                            className="bg-yellow sm:px-5 inline-block py-1 mr-5 text-xl font-bold text-white rounded cursor-pointer"
                                            onClick={() => signUp()}>
                                            {signupBtnText}
                                        </a>
                                    )}
                                </>
                            )}

                            <a
                                href="#sharemodal"
                                className="text-yellow sm:px-6 xl:px-20 sm:py-1 sm:justify-start flex items-center justify-center p-2 mr-5 text-xl font-bold bg-white rounded cursor-pointer"
                                onClick={() => {
                                    setShareLinkCopied(false);
                                    setIsShareModalActive(true);
                                }}>
                                Share
                            </a>
                            {isAdmin() ? (
                                <a
                                    href="#awardce"
                                    className="text-yellow sm:px-6 xl:px-20 inline-block py-1 mr-5 text-xl font-bold bg-white rounded cursor-pointer"
                                    onClick={() => {
                                        setIsAwardCEModalActive(true);
                                    }}>
                                    Award CE
                                </a>
                            ) : null}
                            {/*<a className="border-white-900 sm:px-6 inline-block py-1 text-xl font-bold text-white bg-transparent border rounded cursor-pointer">Add to list</a>*/}
                        </div>
                    </div>
                </div>

                <div className="md:flex sm:px-16 justify-between px-8 py-8">
                    <div className="md:w-2/6">
                        {presentation.learningObjectives ? (
                            <div className={`p-10 ${styles.Objectives}`}>
                                <h2
                                    className={`${styles.BaseColorSemiDark} md:text-2xl lg:text-4xl font-semibold leading-none`}>
                                    Learning objectives
                                </h2>
                                <ul className="mt-5">
                                    {presentation.learningObjectives[0] ? (
                                        <ObjectiveCard text={presentation.learningObjectives[0]} />
                                    ) : null}
                                    {presentation.learningObjectives[1] ? (
                                        <ObjectiveCard text={presentation.learningObjectives[1]} />
                                    ) : null}
                                    {presentation.learningObjectives[2] ? (
                                        <ObjectiveCard text={presentation.learningObjectives[2]} />
                                    ) : null}
                                </ul>
                            </div>
                        ) : null}

                        <div className={`py-10 ${styles.Presenter}`}>
                            <h2 className={`${styles.BaseColorSemiDark} text-3xl font-semibold`}>
                                Educator
                            </h2>
                            <p className={`mb-4`}>
                                <span
                                    className={`block font-semibold text-xl ${styles.BaseColorSemiDark}`}>
                                    {presenter.first_name} {presenter.last_name}
                                </span>
                                <span className={`font-normal text-base ${styles.BaseColor}`}>
                                    {presenter.occupation}
                                </span>
                            </p>

                            <div className="lg:flex items-center w-full">
                                <div className="1/4">
                                    <div className="w-24 h-24 mr-4 overflow-hidden rounded-full">
                                        {!isPresenterProfileImageError ? (
                                            <img
                                                className="object-cover w-full h-full"
                                                alt="Educator"
                                                src={presenterAvatar}
                                                onError={presenterProfileImageError}></img>
                                        ) : (
                                            <MdAccountCircle color="F9B041" size={'5em'} />
                                        )}
                                    </div>
                                </div>

                                <div className="3/4 mt-4">
                                    <ul>
                                        <li
                                            className={`flex items-center font-normal text-base ${styles.BaseColor}`}>
                                            <span className="inline-block">
                                                <img
                                                    alt="Rating Star"
                                                    src={require(`../../images/sample/presentation/rating.png`)}
                                                    className=""></img>
                                            </span>
                                            <span className="ml-2">
                                                {presenter.reviewAvgRating &&
                                                    presenter.reviewAvgRating
                                                        .toString()
                                                        .slice(0, 3)}{' '}
                                                Educator Rating
                                            </span>
                                        </li>
                                        <li
                                            className={`flex items-center font-normal text-base ${styles.BaseColor}`}>
                                            <span className="inline-block">
                                                <img
                                                    alt="Reviews Icon"
                                                    src={require(`../../images/sample/presentation/outlinestar.png`)}
                                                    className=""></img>
                                            </span>
                                            <span className="ml-2">
                                                {presenter.reviewCount} Reviews
                                            </span>
                                        </li>
                                        <li
                                            className={`flex items-center font-normal text-base ${styles.BaseColor}`}>
                                            <span className="inline-block">
                                                <img
                                                    alt="Views Icon"
                                                    src={require(`../../images/sample/presentation/group.png`)}
                                                    className=""></img>
                                            </span>
                                            <span className="ml-2">
                                                {presenter.viewCount} Views
                                            </span>
                                        </li>
                                        <li
                                            className={`flex items-center font-normal text-base ${styles.BaseColor}`}>
                                            <span className="inline-block">
                                                <img
                                                    alt="Right Arrow"
                                                    src={require(`../../images/sample/presentation/arrow.png`)}
                                                    className=""></img>
                                            </span>
                                            <span className="ml-2">
                                                {presenter.presentationCount} Visual Modules
                                            </span>
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            {presenter.about_me ? (
                                <LongText
                                    paraClasses={`font-medium text-base ${styles.BaseColorSemiDark}`}
                                    showMoreClasses="text-base font-bold"
                                    showLessClasses="text-base font-bold"
                                    containerClasses="mt-2"
                                    content={presenter.about_me}
                                    limit={300}></LongText>
                            ) : null}
                        </div>
                    </div>

                    <div className="md:w-4/6">
                        <div className={`md:px-10  ${styles.Description}`}>
                            <h2 className={`${styles.BaseColorSemiDark} text-4xl font-semibold`}>
                                Description
                            </h2>
                            <LongText
                                paraClasses={`${styles.BaseColorSemiDark}  text-base font-medium`}
                                showMoreClasses="text-base font-bold"
                                showLessClasses="text-base font-bold"
                                content={description}
                                limit={500}></LongText>
                        </div>

                        {presentation.rating ? (
                            <div
                                className={`mt-12 md:px-10 grid sm:grid-cols-8 xl:grid-cols-6 ${styles.OverallScore}`}>
                                <h2
                                    className={`${styles.BaseColorSemiDark} text-4xl font-semibold sm:col-start-1 sm:col-end-9 xl:col-start-1 xl:col-end-7`}>
                                    Overall Score
                                </h2>

                                <div className="sm:col-start-1 sm:col-end-3 xl:col-start-1 xl:col-end-2">
                                    <ul className="text-center">
                                        <li>
                                            <h2
                                                className={`font-semibold text-5xl ${styles.BaseColorSemiDark}`}>
                                                {presentation.rating.avg}
                                            </h2>
                                        </li>
                                        <li>
                                            <StarRatings
                                                starDimension="18px"
                                                starSpacing="2px"
                                                rating={presentation.rating.avg}
                                                starRatedColor="orange"
                                                numberOfStars={5}
                                                name="rating"
                                            />
                                        </li>
                                        <li>Course rating</li>
                                    </ul>
                                </div>

                                <div className="sm:col-start-3 sm:col-end-9 xl:col-start-2 xl:col-end-7">
                                    <RatingOverview ratings={[68, 22, 13, 2, 1]} />
                                </div>
                            </div>
                        ) : null}

                        {references ? (
                            <div className={`p-5 md:px-10 mt-10 md:ml-10 ${styles.FeaturedReview}`}>
                                <h2
                                    className={`${styles.BaseColorSemiDark} text-2xl font-semibold`}>
                                    Reference Files
                                </h2>
                                <ReferenceFileList refs={references} />
                            </div>
                        ) : null}

                        {featuredReview ? (
                            <div className={`p-5 md:px-10 mt-10 md:ml-10 ${styles.FeaturedReview}`}>
                                <h2
                                    className={`${styles.BaseColorSemiDark} text-2xl font-semibold`}>
                                    Featured Review
                                </h2>

                                <div className="flex items-center w-full">
                                    <div className="mr-3 overflow-hidden rounded-full">
                                        {!isFeaturedProfileImageError ? (
                                            <img
                                                alt="Review User Avatar"
                                                className={`${styles.ImageSmall} object-cover w-full h-full`}
                                                src={uncacheImageURL(featuredReview.user.picture)}
                                                onError={featuredProfileImageError}></img>
                                        ) : (
                                            <MdAccountCircle color="F9B041" size={'3em'} />
                                        )}
                                    </div>

                                    <div className="w-3/4">
                                        <ul>
                                            <li
                                                className={`${styles.BaseColorSemiDark} text-lg font-semibold`}>
                                                <span>
                                                    {featuredReview.user.first_name}{' '}
                                                    {featuredReview.user.last_name}
                                                </span>
                                            </li>
                                            {/*<li
                                                className={`text-base font-normal  ${styles.BaseColor}`}>
                                                <span>10 Courses</span>
                                            </li>*/}
                                            <li
                                                className={`text-base font-normal ${styles.BaseColor}`}>
                                                <span>
                                                    {featuredReview.user.userReviewCount} reviews
                                                </span>
                                            </li>
                                            <li className="text-xs font-normal"></li>
                                        </ul>
                                    </div>
                                </div>

                                <div className={`mt-2`}>
                                    <StarRatings
                                        starDimension="24px"
                                        starSpacing="2px"
                                        rating={featuredReview.starsAvg}
                                        starRatedColor="orange"
                                        numberOfStars={5}
                                        name="rating"
                                    />
                                    <span className={`${styles.BaseColor} ml-4`}>
                                        {featuredReview.updated.slice(0, 10)}
                                    </span>
                                </div>

                                <LongText
                                    paraClasses={`${styles.BaseColorSemiDark}  text-base font-medium`}
                                    showMoreClasses="text-base font-bold"
                                    showLessClasses="text-base font-bold"
                                    containerClasses="mt-2"
                                    content={featuredReview.comment}
                                    limit={99999}></LongText>

                                {/*<div>
                                    <p className={`mt-8 font-normal text-base cursor-pointer ${styles.BaseColor}`}>
                                        Was this review helpful?
                                    </p>
                                    <a className={`${styles.FeaturedActionButton} pl-3 pr-3 mr-2 font-medium text-xs cursor-pointer`}>
                                        YES
                                    </a>
                                    <a className={`${styles.FeaturedActionButton} pl-3 pr-3  mr-2 font-medium text-xs cursor-pointer`}>
                                        NO
                                    </a>
                                    <a className={`${styles.FeaturedReportActionButton} text-base font-normal cursor-pointer`}>
                                        Report
                                    </a>
                                </div>*/}
                            </div>
                        ) : null}
                    </div>
                </div>

                {shareModal}
                {awardCEModal}
                {onFinishModal}
                {videoPreviewModal}
            </Fragment>
        );
    }

    if (!loading && error) {
        containerUI = <NoResult message={`Presentation is no longer available`} />;
    }

    return (
        <div className={`App ${styles.AppContainer}`}>
            <Topbar cmsEnabled={false} orgName={orgName} />
            {containerUI}
            <Footer />
        </div>
    );
};

export default PresentationPage;
